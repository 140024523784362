<template>
  <basic-card
    :title="`${$i18n.t('$l.case.participantInvestigation')} (${$i18n.t(`$l.case.${currentCase.type}`)})`"
    text-class="body-1 font-weight-light ma-0 pa-0 mt-2"
  >
    <v-row
      class="ma-0 pa-0"
    >
      <v-col
        xl="9"
        lg="9"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="ma-0 pa-0"
        :class="{'pr-3' : $vuetify.breakpoint.mdAndUp}"
      >
        <BasicCaseData
          class="mb-3"
        />
      </v-col>
      <v-col
        xl="3"
        lg="3"
        md="4"
        sm="12"
        xs="12"
        cols="12"
        class="ma-0 pa-3 greyf6 mb-3"
        style="border-radius: 0.4em"
      >
        <basic-resp-row
          left="$l.overview.activeProductName"
          :right="caseActivationData.activeProduct.name"
          :call-result="callResult"
          width="100%"
          rowcss="body-2 ma-0 mb-2 info1--text"
        />
        <basic-resp-row
          left="$l.overview.packageInvestigations"
          :right="`${caseActivationData.activeProduct.packageInvestigations}`"
          :call-result="callResult"
          width="100%"
          rowcss="body-2 ma-0 mb-2 info1--text"
        />
        <basic-resp-row
          left="$l.overview.leftPackageInvestigations"
          :right="`${caseActivationData.activeProduct.leftPackageInvestigations}`"
          :call-result="callResult"
          width="100%"
          :rowcss="caseActivationData.activeProduct.leftPackageInvestigations > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
        />
        <span
          v-if="caseActivationData.overPackageInvestigations > 0"
        >
          <basic-resp-row
            left="$l.overview.overPackageInvestigations"
            :right="`${caseActivationData.overPackageInvestigations}`"
            :call-result="callResult"
            width="100%"
            rowcss="body-2 ma-0 mb-2 info1--text"
          />
          <basic-resp-row
            left="$l.overview.leftOverPackageInvestigations"
            :right="`${caseActivationData.leftOverPackageInvestigations}`"
            :call-result="callResult"
            width="100%"
            :rowcss="caseActivationData.leftOverPackageInvestigations > 5 ? 'body-2 ma-0 mb-2 success--text' : 'body-2 ma-0 mb-2 error--text'"
          />
        </span>
      </v-col>
    </v-row>

    <div class="d-flex justify-end">
      <v-btn
        v-if="currentCase.type === 'TransactionCase' && !switchParticipantListToTransactionAssessment"
        :disabled="isSelectedParticipant"
        class="primary ml-3"
        elevation="0"
        @click="openParticipantDialog"
      >
        {{ "$l.case.addParticipant" | translate }}
      </v-btn>

      <v-btn
        :disabled="progressEnabled()"
        class="primary ml-3"
        elevation="0"
        @click="progress"
      >
        {{ "$l.app.next" | translate }}
      </v-btn>
    </div>

    <app-api-call
      class="ma-0 pa-0 pb-0 my-3"
      :call-result="callResult"
    />

    <div
      v-if="callResult.finished"
    >
      <basic-service-required
        v-if="caseActivationData.suspendedActivation"
        title="$l.services.suspendedActivation"
        dclass="error--text"
        label="$l.services.renewPackage"
        class="my-3"
      />

      <basic-service-required
        v-if="caseActivationData.suspendedInvestigations"
        title="$l.services.suspendedInvestigations"
        dclass="error--text"
        push-to="/services/investigation"
        label="$l.services.addInvestigations"
        class="my-3"
      />
    </div>

    <v-container
      v-if="callResult.finished"
      fluid
      class="ma-0 pa-0"
    >
      <template
        v-if="!switchParticipantListToTransactionAssessment"
      >
        <basic-note-hint
          v-if="!participant.id && currentCase.type === 'TransactionCase'"
          class="mt-3"
        >
          {{ $i18n.t('$l.hint.caseWrapper1') }}
        </basic-note-hint>

        <basic-note-hint
          v-if="!participant.id && currentCase.type !== 'TransactionCase'"
          class="mt-3"
        >
          {{ $i18n.t('$l.hint.caseWrapper2') }}
        </basic-note-hint>
      </template>
      <basic-note-hint
        v-if="switchParticipantListToTransactionAssessment"
        class="mt-3"
      >
        {{ $i18n.t('$l.hint.caseWrapper3') }}
      </basic-note-hint>

      <v-row>
        <v-col>
          <transition
            mode="out-in"
            :css="false"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <template
              v-if="isSelectedParticipant"
            >
              <ParticipantStepper
                class="float-right"
                :style="{'max-width': $vuetify.breakpoint.smAndDown ? '80%' : '90%'}"
                :participant-id="participant.id"
                @cancel-stepper="participantDeselected"
              />
            </template>
          </transition>
          <participant-list
            v-if="!switchParticipantListToTransactionAssessment"
            ref="participantList"
            :participant-selected-id="participant.id"
            @participant-selected="participantSelected"
          />
          <transition
            mode="out-in"
            :css="false"
            @before-enter="beforeEnter"
            @enter="enterTransaction"
            @leave="leave"
          >
            <TransactionAssessment
              v-if="switchParticipantListToTransactionAssessment"
              @confirm-transacction-assessment="transactionAssessmentConfirmed = true"
              @next="progress()"
              @form-value-chenge="transactionAssessmentConfirmed = false"
            />
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <add-participant-dialog
      v-if="showParticipantDialog"
      :data="{participant:participantDefault}"
      :current-case="currentCase"
      @close-dialog="closeParticipantDialog"
    />
  </basic-card>
</template>

<script>
import BasicCaseData from './BasicCaseData.vue'
import ParticipantList from './participants/ParticipantList.vue'
import ParticipantStepper from './participants/ParticipantStepper.vue'
import AddParticipantDialog from './participants/AddParticipantDialog.vue'
import TransactionAssessment from './TransactionAssessment.vue'

import Velocity from 'velocity-animate'

export default {
  name: 'CaseWrapper',
  components: {
    BasicCaseData,
    ParticipantList,
    ParticipantStepper,
    AddParticipantDialog,
    TransactionAssessment
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      participant: {},
      showParticipantDialog: false,
      participantDefault: JSON.parse(JSON.stringify(this.$utils.participantDefault)),
      caseActivationData: { activeProduct: {} },
      switchParticipantListToTransactionAssessment: false,
      transactionAssessmentConfirmed: false
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    isSelectedParticipant () {
      if (this.participant.id) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    if (this.currentCase.investigations.filter(i => i.clientApi === 'TA').length > 0) {
      this.transactionAssessmentConfirmed = this.currentCase.investigations.filter(i => i.clientApi === 'TA')[0].finished
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/getActivationData')
        .then(r => {
          this.caseActivationData = r.data
          if (!this.caseActivationData.activeProduct) {
            this.caseActivationData.activeProduct = {}
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    closeParticipantDialog () {
      this.showParticipantDialog = false
      this.participantDefault = JSON.parse(JSON.stringify(this.$utils.participantDefault))
    },
    participantSelected (participant) {
      this.participant = participant
    },
    participantDeselected () {
      this.participant = {}
      this.init()
    },
    progressEnabled () {
      let allTasksOk = true
      Array.from(this.currentCase.participants, p => {
        if (!p.result.finalStatus) {
          allTasksOk = false
        } else {
          if (p.result.finalStatus !== 'FIN') {
            allTasksOk = false
          }
        }
      })
      if (!(this.currentCase.participants.length > 0)) {
        allTasksOk = false
      }

      if (this.switchParticipantListToTransactionAssessment) {
        return !this.transactionAssessmentConfirmed || !allTasksOk
      }
      return !(allTasksOk && !this.isSelectedParticipant)
    },
    progress () {
      if (this.currentCase.type === 'TransactionCase') {
        if (!this.progressEnabled()) {
          if (this.switchParticipantListToTransactionAssessment === true) {
            this.redirectToCaseResolution()
          } else {
            this.switchParticipantListToTransactionAssessment = true
          }
        }
      } else {
        this.redirectToCaseResolution()
      }
    },
    redirectToCaseResolution () {
      this.callResult.error = null
      this.callResult.finished = false
      this.currentCase.status = 'FIN'
      this.computeRiskLevels()
      let clonedCurrentCase = JSON.parse(JSON.stringify(this.$store.getters.getCurrentCase))
      this.$xapi.put('case/update', clonedCurrentCase)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          if (this.currentCase.type === 'TransactionCase') {
            this.$router.push({ name: 'ResolveCase', params: { caseId: this.currentCase.caseId } })
          } else {
            this.$router.push({ name: 'ResolveCasePerson', params: { caseId: this.currentCase.caseId } })
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
    },
    computeRiskLevels () {
      let investigationRiskLevel = 0
      Array.from(this.currentCase.participants, p => {
        Array.from(p.investigations, inv => {
          let invResult = inv.result.riskLevel
          if (investigationRiskLevel < invResult) {
            investigationRiskLevel = invResult
          }
        })
        p.result.riskLevel = investigationRiskLevel
        investigationRiskLevel = 0
        Array.from(p.investigations, inv => {
          let invResult = inv.result.userDefinedRiskLevel > -1 ? inv.result.userDefinedRiskLevel : inv.result.riskLevel
          if (investigationRiskLevel < invResult) {
            investigationRiskLevel = invResult
          }
        })
        p.result.userDefinedRiskLevel = investigationRiskLevel
        investigationRiskLevel = 0
      })
      let participantRiskLevel = 0
      Array.from(this.currentCase.participants, p => {
        let pResult = p.result.riskLevel
        if (participantRiskLevel < pResult) {
          participantRiskLevel = pResult
        }
      })
      this.currentCase.result.riskLevel = participantRiskLevel
      participantRiskLevel = 0
      Array.from(this.currentCase.participants, p => {
        let pResult = p.result.userDefinedRiskLevel
        if (participantRiskLevel < pResult) {
          participantRiskLevel = pResult
        }
      })
      this.currentCase.result.userDefinedRiskLevel = participantRiskLevel
    },
    beforeEnter (el) {
      el.style.opacity = 0
      el.style.width = '0%'
    },
    enter (el, done) {
      Velocity(el,
        { opacity: 1, width: '90%' },
        { duration: 1000, easing: 'easeIn', complete: done })
    },
    leave (el, done) {
      Velocity(el,
        { opacity: 0, width: '0%' },
        { duration: 1000, easing: 'easeOut', complete: done })
    },
    openParticipantDialog () {
      this.showParticipantDialog = true
    },
    enterTransaction (el, done) {
      Velocity(el,
        { opacity: 1, width: '100%' },
        { duration: 1000, easing: 'easeIn', complete: done })
    }
  }
}
</script>
