<template>
  <v-container
    fluid
    class="ma-0 pa-0 pb-3"
  >
    <v-expansion-panels>
      <v-expansion-panel
        v-model="panel"
        class="round"
        :class="[{ error:match.match, success:match.nomatch, warning:match.maybe },
                 {'white--text':isSelected(match)},{'grey--text text--darken-2':!isSelected(match)}]"
        @change="initMagicGrid"
      >
        <v-expansion-panel-header class="ma-0 pa-2">
          <v-container
            fluid
            class="ma-0 pa-0"
          >
            <v-row class="ma-0 pa-0 d-flex align-center okaml_break_word">
              <ImageComponent
                :urls="match.detail.image"
                class="my-1 mx-2 "
              />

              <div
                class="my-1 mx-2"
              >
                <div>
                  <div
                    :class="[{'white--text':isSelected(match)},
                             {'grey--text text--darken-2':!isSelected(match)}]"
                  >
                    {{ '$l.case.primaryNameHeader' | translate }}
                  </div>
                  <span class="text-h5"> {{ match.primaryName }} </span>
                </div>
              </div>

              <div
                v-if="checkIfEmpty(match.detail.age)"
                class="my-1 mx-2"
              >
                <div v-if="checkIfEmpty(match.detail.birth)">
                  <div
                    :class="[{'white--text':isSelected(match)},
                             {'grey--text text--darken-2':!isSelected(match)}]"
                  >
                    {{ '$l.case.asofdate' | translate }}
                  </div>
                  <span class="text-h5"> {{ match.detail.age[0].asofdate }} </span>
                </div>
              </div>
              <div
                v-else
                class="my-1 mx-2"
              >
                <div v-if="checkIfEmpty(match.detail.birth)">
                  <div
                    :class="[{'white--text':isSelected(match)},
                             {'grey--text text--darken-2':!isSelected(match)}]"
                  >
                    {{ '$l.case.asofdate' | translate }}
                  </div>
                  <span class="text-h5"> {{ match.detail.birth[0].date }} </span>
                </div>
              </div>

              <v-spacer />

              <div
                class="d-flex align-end"
              >
                <v-spacer />
                <v-btn
                  v-if="isSelected(match)"
                  color="info"
                  class="ma-1 button"
                  @click.stop="resetSelected(match)"
                >
                  {{ '$l.app.edit' | translate }}
                </v-btn>
                <div v-else>
                  <v-btn
                    color="success"
                    class="ma-1 button"
                    @click.stop="saveSelected(match, 'nomatch')"
                  >
                    {{ '$l.investigation.btnNoMatch' | translate }}
                  </v-btn>
                  <v-btn
                    color="warning"
                    class="ma-1 button"
                    @click.stop="saveSelected(match, 'maybe')"
                  >
                    {{ '$l.investigation.btnMaybe' | translate }}
                  </v-btn>
                  <v-btn
                    color="error"
                    class="ma-1 button"
                    @click.stop="saveSelected(match, 'match')"
                  >
                    {{ '$l.investigation.btnMatch' | translate }}
                  </v-btn>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          class="match-detail"
        >
          <v-progress-circular
            v-show="!showMatchDetailData"
            class="progress-bar"
            indeterminate
            color="grey"
          />
          <MatchDetail
            ref="match_detail"
            :match="match"
            :panel="panelValue"
            @keydown.enter="handleSendMessage"
            @content-updated="closeExtensionPanel"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import MatchDetail from './MatchDetail.vue'
import ImageComponent from './ImageComponent.vue'

export default {
  name: 'SelectMatchDialog',
  components: {
    MatchDetail,
    ImageComponent
  },
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validSources: '',
      panel: [],
      panelValue: 0,
      collapsed: false,
      match_detail2: this.$refs.match_detail,
      showMatchDetailData: false,
      firstOpenContent: true
    }
  },
  watch: {
    panel (newValue, oldValue) {
      this.panelValue++
    }
  },
  methods: {
    saveSelected (match, action) {
      switch (action) {
        case 'match': {
          match.match = true
          match.nomatch = false
          match.maybe = false
          break
        }
        case 'nomatch': {
          match.match = false
          match.nomatch = true
          match.maybe = false
          break
        }
        case 'maybe': {
          match.match = false
          match.nomatch = false
          match.maybe = true
          break
        }
        default: break
      }
    },
    isSelected (match) {
      return match.match || match.maybe || match.nomatch
    },
    resetSelected (match) {
      match.match = false
      match.maybe = false
      match.nomatch = false
    },
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    },
    checkSrc (src) {
      fetch(src, { mode: 'no-cors' }).then(() => {
        if (!this.validSources.includes(src)) {
          this.validSources = this.validSources + ' ' + src
        }
      })
      return true
    },
    closeExtensionPanel () {
      this.panel = []
    },
    initMagicGrid () {
      if (this.firstOpenContent) {
        this.firstOpenContent = false
        fetch('')
          .finally(() => {
            this.showMatchDetailData = true
            this.$refs.match_detail.initMagicGrid()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .round {
    border-radius: 10px !important;
  }
  .match-detail{
    padding-top: 10px;
    border-top: 1px solid rgb(226, 226, 226);
  }
  .button {
    min-width: 12em !important
  }
</style>
