<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <app-api-call
      class="ma-0 pa-0 pb-0 mt-4"
      :call-result="callResult"
    />
    <v-card
      elevation="0"
    >
      <v-form
        ref="transactionAssessmentForm"
        lazy-validation
        class="ma-0 pa-0"
      >
        <v-switch
          v-for="(assesment, idx) in [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ]"
          :key="idx"
          v-model="selected"
          :label="idx+1+'. '+$i18n.t('$l.case.transactionAssessment'+assesment)"
          :value="assesment"
          hide-details
        />
        <v-row>
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="12"
            cols="12"
          >
            <v-combobox
              v-model="riskLevel"
              :items="riskLevels"
              :label="$i18n.t('$l.case.riskLevelTransaction')"
              :error-messages="getErrorMessageRiskLevel()"
            />
          </v-col>
          <v-col
            xl="9"
            lg="9"
            md="9"
            sm="9"
            xs="12"
            cols="12"
          >
            <v-textarea
              v-model="description"
              :label="$i18n.t('$l.investigation.evaluationJustification')"
              maxlength="512"
              counter
              rows="2"
              :error-messages="getErrorMessageDescription()"
            />
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            class="primary ml-3"
            elevation="0"
            :loading="loadingButton"
            @click="confirm"
          >
            {{ $i18n.t('$l.app.confirm') }}
          </v-btn>
          <v-btn
            class="primary ml-3"
            elevation="0"
            :disabled="disableNextButn"
            @click="next"
          >
            {{ $i18n.t('$l.app.next') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'TransactionAssessment',
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      selected: [],
      description: null,
      riskLevel: null,
      transactionAssessmentConfirmed: false,
      disableNextButn: true,
      riskLevels: [
        {
          text: this.$i18n.t('$l.case.level0'),
          value: 0
        },
        {
          text: this.$i18n.t('$l.case.level1'),
          value: 1
        },
        {
          text: this.$i18n.t('$l.case.level2'),
          value: 2
        }
      ],
      loadingButton: false
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    isSelectedParticipant () {
      if (this.participant.id) {
        return true
      } else {
        return false
      }
    }
  },
  validations: {
    riskLevel: { required },
    description: { required }
  },
  watch: {
    selected (val) {
      var caseInvestigations = this.currentCase.investigations.filter(i => i.clientApi === 'TA')
      if (caseInvestigations && caseInvestigations.length > 0) {
        if (this.selected !== caseInvestigations[0].result.transactionAssessment) {
          this.disableNextButn = true
          this.$emit('form-value-chenge')
        }
      }
    },
    riskLevel (val) {
      var caseInvestigations = this.currentCase.investigations.filter(i => i.clientApi === 'TA')
      if (caseInvestigations && caseInvestigations.length > 0) {
        if (this.riskLevel.value !== caseInvestigations[0].result.riskLevel) {
          this.disableNextButn = true
          this.$emit('form-value-chenge')
        }
      }
    },
    description (val) {
      var caseInvestigations = this.currentCase.investigations.filter(i => i.clientApi === 'TA')
      if (caseInvestigations && caseInvestigations.length > 0) {
        if (this.description !== caseInvestigations[0].result.info) {
          this.disableNextButn = true
          this.$emit('form-value-chenge')
        }
      }
    }
  },
  created () {
    if (this.currentCase.investigations.filter(i => i.clientApi === 'TA').length > 0) {
      var investigation = this.currentCase.investigations.filter(i => i.clientApi === 'TA')[0]
      this.disableNextButn = !investigation.finished
      var result = investigation.result
      this.selected = result.transactionAssessment
      this.description = result.info
      this.riskLevel = this.riskLevels[result.riskLevel]
    } else { // TA vas not submited with user, automaticly select assesments
      if (this.isHighRisk('CNTR')) {
        this.selected.push(10)
      }
      if (this.isHighRisk('CSSDN') || this.isHighRisk('USDN') || this.isHighRisk('EUSL')) {
        this.selected.push(11)
      }
      if (this.selected.length > 0) {
        this.riskLevel = this.riskLevels[2]
      }
    }
  },
  methods: {
    isHighRisk (clientApi) { // check risk on tasks wi clientApi
      var result = false
      this.currentCase.participants.forEach(function myFunction (participant) {
        var investigations = participant.investigations.filter(i => i.clientApi === clientApi)
        if (investigations.length > 0) {
          if (investigations[0].result.userDefinedRiskLevel !== -1) {
            result = investigations[0].result.userDefinedRiskLevel === 2
          } else {
            result = investigations[0].result.riskLevel === 2
          }
        }
      })
      return result
    },
    confirm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        var payload = {
          riskLevel: this.riskLevel.value,
          info: this.description,
          transactionAssessment: this.selected
        }
        this.loadingButton = true
        this.$xapi.post(`case/confirmTransactionAssessment?caseId=${this.currentCase.caseId}`, payload)
          .then(r => {
            this.$emit('confirm-transacction-assessment')
            this.$store.commit('SET_CURRENT_CASE', r.data)
            this.transactionAssessmentConfirmed = true
            this.disableNextButn = false
          })
          .catch(e => {
            this.$store.commit('SET_CURRENT_CASE', {})
            this.callResult.error = e.response.localizedMessage
          }).finally(() => {
            this.callResult.finished = true
            this.loadingButton = false
          })
      }
    },
    next () {
      this.$emit('next')
    },
    getErrorMessageRiskLevel () {
      if (this.$v.riskLevel.$dirty && !this.$v.riskLevel.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    getErrorMessageDescription () {
      if (this.$v.description.$dirty && !this.$v.description.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    }
  }
}
</script>

<style>
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    font-size: 14px;
  }
</style>
