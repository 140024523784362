<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    save-button
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    :loading-save-button="!callResult.finished"
    @close-dialog="close()"
    @save-dialog="saveDialog"
  >
    <basic-inv-result :investigation="data.investigation" />

    <template
      v-if="!foundInORSR"
    >
      <basic-note-hint
        class="ma-3"
      >
        {{ $i18n.t('$l.investigation.PWAnote') }}
      </basic-note-hint>

      <app-api-call
        class="ma-0 pa-3 pb-0"
        :call-result="callResult"
      />

      <v-card-title>
        <v-radio-group
          v-model="confirmRadioGroup"
          :disabled="!callResult.finished"
          column
        >
          <v-radio
            :label="'$l.investigation.PWAsuccessfullyCompleted' | translate"
            color="primary"
            :value="0"
            @change="personFoundInOtherRegister"
          />
          <v-radio
            :label="'$l.investigation.PWAcouldNotBeExecuted' | translate"
            color="error"
            :value="2"
            @change="personInNoRegister"
          />
        </v-radio-group>
      </v-card-title>
    </template>
  </basic-dialog>
</template>

<script>
export default {
  name: 'PersonWithActivityDialog',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      foundInORSR: this.data.investigation.result.foundPWAInDB,
      confirmRadioGroup: this.data.investigation.result.resolved ? this.data.investigation.result.riskLevel : -1,
      callResult: { finished: true, error: null, info: null },
      storedResult: {}
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.storedResult = JSON.parse(JSON.stringify(this.data.investigation.result))
  },
  methods: {
    close () {
      this.data.investigation.result = this.storedResult
      this.$emit('close-dialog')
    },
    saveDialog () {
      if (!(this.confirmRadioGroup >= 0)) {
        return
      }
      if (!this.foundInORSR) {
        this.callResult.finished = false
        this.$xapi.put(`investigation/processPWATask?caseId=${this.currentCase.caseId}&participantId=${this.data.participant.id}&selectedRWA=${this.confirmRadioGroup}&info=${this.data.investigation.result.info}`)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
            this.close()
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          }).finally(() => {
            this.callResult.finished = true
          })
      } else { this.close() }
    },
    personFoundInOtherRegister () {
      this.data.investigation.result.riskLevel = 0
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.PWAotherRegister' })
    },
    personInNoRegister () {
      this.data.investigation.result.riskLevel = 2
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.PWAnoRegister' })
    }
  }
}
</script>
