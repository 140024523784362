<template>
  <v-card
    class="ma-0 pa-0 matchdet other-known-names"
    max-height="500px"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.otherKnownNames' | translate }}</span>
    </v-card-title>
    <v-card-text
      v-for="(item, index) in match.detail.name"
      :key="index"
    >
      <div v-if="checkIfEmpty(item.type)">
        {{ $i18n.t('$l.case.nameType') + ': ' + item.type }}
      </div>

      <div v-if="checkIfEmpty(item.wholename)">
        {{ $i18n.t('$l.case.wholename') + ': ' + item.wholename }}
      </div>

      <div v-if="checkIfEmpty(item.title)">
        {{ $i18n.t('$l.case.title') + ': ' + item.title }}
      </div>

      <div v-if="checkIfEmpty(item.gender)">
        {{ $i18n.t('$l.case.gender') + ': ' + item.gender }}
      </div>

      <div v-if="checkIfEmpty(item.function)">
        {{ $i18n.t('$l.case.function') + ': ' + item.function }}
      </div>

      <div v-if="checkIfEmpty(item.language)">
        {{ $i18n.t('$l.case.language') + ': ' + item.language }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OtherKnownNames',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .other-known-names {
    overflow-y: scroll;
  }
</style>
