<template>
  <v-col>
    <v-list-item-content class="ma-0 pa-0 basicCaseDataCol_lh">
      <v-list-item-subtitle>{{ $i18n.t(label) }}</v-list-item-subtitle>
      <v-list-item-title class="subtitle-1">
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-col>
</template>

<script>
export default {
  name: 'BasicCaseDataCol',
  props: {
    label: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>
