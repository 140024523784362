<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.image' | translate }}</span>
    </v-card-title>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div
          v-for="(item, index) in match.detail.image"
          :key="index"
          class="d-inline"
        >
          <v-avatar
            v-if="true"
            class="ma-3"
            color="greyee"
          >
            <v-img
              :src="item"
              @click="openLink(item)"
              v-on="on"
            />
          </v-avatar>
          <v-avatar
            v-else
            class="ma-3"
            color="greyee"
          >
            mdi-account-circle
          </v-avatar>
        </div>
      </template>
      <span class="round">{{ '$l.case.clickForFullView' | translate }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>

export default {
  name: 'ImageComp',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    openLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
