<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.birth' | translate }}</span>
    </v-card-title>

    <v-card-text
      v-for="(item, index) in match.detail.birth"
      :key="index"
    >
      <div v-if="checkIfEmpty(item.date)">
        {{ $i18n.t('$l.case.dateBirth') + ': ' + item.date }}
      </div>

      <div v-if="checkIfEmpty(item.place)">
        {{ $i18n.t('$l.case.place') + ': ' + item.place }}
      </div>

      <div v-if="checkIfEmpty(item.country)">
        {{ $i18n.t('$l.case.country') + ': ' + item.country }}
      </div>

      <div v-if="checkIfEmpty(item.state)">
        {{ $i18n.t('$l.case.state') + ': ' + item.state }}
      </div>

      <div v-if="checkIfEmpty(item.other)">
        {{ $i18n.t('$l.case.other') + ': ' + item.other }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BirthInfo',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
