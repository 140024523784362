<template>
  <v-form
    ref="addressForm"
    lazy-validation
    class="ma-0 pa-0"
  >
    <v-row>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-text-field
          v-model="address.line1"
          :outlined="outlinedAddressFields"
          :error-messages="line1ErrorMessage()"
          :label="$i18n.t('$l.address.line1')"
          maxlength="128"
          counter
          @input="$v.address.line1.$touch()"
          @blur="blurField($v.address.line1)"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-text-field
          v-model="address.line2"
          :outlined="outlinedAddressFields"
          :label="$i18n.t('$l.address.line2')"
          maxlength="128"
          counter
          @blur="blurField($v.address.line2)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-text-field
          v-model="address.city"
          :outlined="outlinedAddressFields"
          :error-messages="cityErrorMessage()"
          :label="$i18n.t('$l.address.city')"
          maxlength="64"
          counter
          @input="$v.address.city.$touch()"
          @blur="blurField($v.address.city)"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-text-field
          v-model="address.state"
          :outlined="outlinedAddressFields"
          :label="$i18n.t('$l.address.state')"
          maxlength="64"
          counter
          @input="$v.address.state.$touch()"
          @blur="blurField($v.address.state)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-text-field
          v-model="address.zip"
          :outlined="outlinedAddressFields"
          :error-messages="zipErrorMsg()"
          :label="$i18n.t('$l.address.zip')"
          maxlength="16"
          counter
          @input="$v.address.zip.$touch()"
          @blur="blurField($v.address.zip)"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        cols="12"
      >
        <v-autocomplete
          :key="$i18n.locale"
          v-model="address.country"
          clearable
          :outlined="outlinedAddressFields"
          :items="$store.getters.getCountries"
          :label="$i18n.t('$l.address.country')"
          :filter="countryFilter"
          :item-value="item => item.iso3166_1"
          :item-text="'name'"
          :error-messages="countryErrorMsg()"
          @input="$v.address.country.$touch()"
          @blur="blurField($v.address.country)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddressForm',
  props: {
    outlinedAddressFields: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      address: {}
    }
  },
  validations: {
    address: {
      line1: {
        required
      },
      line2: {
      },
      city: {
        required
      },
      state: {

      },
      zip: {
        required
      },
      country: {
        required
      }
    }

  },
  methods: {
    validateAddressForm () {
      // validate only of one of field is filled
      if (this.isSomeFieldFilled()) {
        this.$v.$reset()
        this.$v.$touch()
        let valid = !this.$v.$invalid & this.$refs.addressForm.validate()
        return valid === 1
      } else {
        this.$v.$reset()
        return true
      }
    },
    resetValidation () {
      this.$refs.addressForm.resetValidation()
    },
    countryFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso3166_1.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textOne.normalize('NFD').replace(/[\u0300-\u036F]/g, '').indexOf(searchText) > -1
    },
    validateAndSave () {
      this.$emit('process-form')
    },
    line1ErrorMessage () {
      if (this.$v.address.line1.$dirty && !this.$v.address.line1.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    cityErrorMessage () {
      var message = ''
      if (this.$v.address.city.$dirty && !this.$v.address.city.required) {
        message = this.$i18n.t('$l.app.required')
      }
      return message
    },
    zipErrorMsg () {
      var message = ''
      if (this.$v.address.zip.$dirty && !this.$v.address.zip.required) {
        message = this.$i18n.t('$l.app.required')
      }
      return message
    },
    countryErrorMsg () {
      var message = ''
      if (this.$v.address.country.$dirty && !this.$v.address.country.required) {
        message = this.$i18n.t('$l.app.required')
      }
      return message
    },
    getAddress () {
      return this.address
    },
    isSomeFieldFilled () {
      var result = (this.address.line1 && this.address.line1 !== '') ||
      (this.address.line2 && this.address.line2 !== '') ||
      (this.address.zip && this.address.zip !== '') ||
      (this.address.city && this.address.city !== '') ||
      (this.address.state && this.address.state !== '') ||
      (this.address.country && this.address.country !== '')
      return result
    },
    blurField (field) {
      field.$touch()
      if (!this.isSomeFieldFilled()) {
        this.$v.$reset()
      }
    }
  }
}
</script>
