<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.detail' | translate }}</span>
    </v-card-title>

    <v-card-text>
      <!-- <div v-if="checkIfEmpty(match.detail.id)">
          {{ '$l.case.idDetail' | translate }} {{ ": "+ match.detail.id }}
        </div> -->

      <div v-if="checkIfEmpty(match.detail.sourceDb)">
        {{ '$l.case.sourceDb' | translate }} {{ ": "+ match.detail.sourceDb }}
      </div>

      <!-- <div v-if="checkIfEmpty(match.detail.lastModified)">
          {{ '$l.case.lastModified' | translate }} {{ ": "+ match.detail.lastModified }}
        </div>

        <div v-if="checkIfEmpty(match.detail.version)">
          {{ '$l.case.version' | translate }} {{ ": "+ match.detail.version }}
        </div> -->

      <div v-if="checkIfEmpty(match.detail.primaryName)">
        {{ '$l.case.primaryName' | translate }} {{ ": "+ match.detail.primaryName }}
      </div>

      <!-- <div v-if="checkIfEmpty(match.detail.createdDate)">
          {{ '$l.case.createdDate' | translate }} {{ ": "+ match.detail.createdDate }}
        </div> -->

      <div v-if="checkIfEmpty(match.detail.score)">
        {{ '$l.case.score' | translate }} {{ ": "+ match.detail.score }}
      </div>

      <!-- <div v-if="checkIfEmpty(match.detail.uid)">
          {{ '$l.case.uid' | translate }} {{ ": "+ match.detail.uid }}
        </div>

        <div v-if="checkIfEmpty(match.detail.category)">
          {{ '$l.case.category' | translate }} {{ ": "+ match.detail.category }}
        </div>

        <div v-if="checkIfEmpty(match.detail.updatedDate)">
          {{ '$l.case.updatedDate' | translate }} {{ ": "+ match.detail.updatedDate }}
        </div>

        <div v-if="checkIfEmpty(match.detail.subCategory)">
          {{ '$l.case.subCategory' | translate }} {{ ": "+ match.detail.subCategory }}
        </div>

        <div v-if="checkIfEmpty(match.detail.editor)">
          {{ '$l.case.editor' | translate }} {{ ": "+ match.detail.editor }}
        </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
