<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.citizenship' | translate }}</span>
    </v-card-title>

    <v-card-text
      v-for="(item, index) in match.detail.citizenshipCountry"
      :key="index"
    >
      <div v-if="checkIfEmpty(item)">
        {{ item }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Citizenship',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
