<template>
  <div>
    <v-stepper
      v-model="stepperState"
      v-bind="$attrs"
    >
      <v-stepper-header
        style="overflow: hidden"
      >
        <v-stepper-step
          :key="1"
          :complete="stepperState > 1"
          step="1"
        >
          {{ '$l.case.form' | translate }}
        </v-stepper-step>

        <v-divider class="ma-0 pa-0" />

        <v-stepper-step
          :key="2"
          :complete="stepperState > 2"
          step="2"
        >
          {{ '$l.case.taskList' | translate }}
        </v-stepper-step>

        <v-divider class="ma-0 pa-0" />

        <v-stepper-step
          :key="3"
          :complete="stepperState > 3"
          step="3"
        >
          {{ '$l.case.evaluation' | translate }}
        </v-stepper-step>
      </v-stepper-header>

      <app-api-info
        :api-info="apiError"
        class="ma-3"
      />

      <v-card-actions
        class="ma-0 pa-0 mx-3"
      >
        <v-btn
          dark
          text
          class="primary"
          @click="cancelStepper()"
        >
          {{ "$l.app.back" | translate }}
        </v-btn>

        <v-spacer />

        <v-btn
          dark
          text
          class="primary"
          :loading="savingInProgress"
          @click="nextStep()"
        >
          {{ stepperState === 3 ? $i18n.t('$l.app.confirm') : $i18n.t('$l.app.next') }}
        </v-btn>
      </v-card-actions>

      <v-card-text
        v-if="stepperState === 2 && someAreNotFinished"
        class="ma-0 pa-0 body-1 okaml_cursor_pointer info1--text mt-3 ml-3"
        @click="restartInvalidInvestigations()"
      >
        {{ "$l.process.restartInvalidInvestigations" | translate }}
      </v-card-text>

      <v-stepper-items>
        <v-stepper-content
          step="1"
          class="ma-0 pa-3"
        >
          <PhysicalPersonForm
            v-if="participant.type==='PhysicalPerson'"
            ref="personFormComponent"
            :participant-id="participant.id"
            @process-form="nextStep()"
          />
          <LegalEntityForm
            v-else-if="participant.type==='LegalEntity'"
            ref="personFormComponent"
            :participant-id="participant.id"
            @process-form="nextStep()"
          />
        </v-stepper-content>

        <v-stepper-content
          step="2"
          class="ma-0 pa-3"
        >
          <TaskList
            ref="taskList"
            :participant-id="participant.id"
          />
        </v-stepper-content>

        <v-stepper-content
          step="3"
          class="ma-0 pa-3"
        >
          <evaluation-list
            :participant="participant"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <basic-dialog
      v-model="confirmStepDialog"
      max-width="600"
      title="$l.app.dataCheck"
      :loading-save-button="stepDialogLoading"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmStepDialog = false"
      @save-dialog="confirmStep()"
    >
      <v-card-text>
        <app-api-info
          :api-info="apiError"
        />
        {{ $i18n.t('$l.app.afterConfirm') }}
      </v-card-text>
      <v-card-text
        v-if="confirmationText"
        class="pt-0"
      >
        <table>
          <td>
            <v-icon
              large
              color="warning darken-1"
              class="pt-5"
            >
              mdi-alert
            </v-icon>
          </td>
          <td class="pl-2">
            {{ confirmationText }}
          </td>
        </table>
      </v-card-text>
    </basic-dialog>
  </div>
</template>

<script>
import PhysicalPersonForm from '@/components/investigation/processCase/stepper/physicalPerson/PhysicalPersonForm.vue'
import LegalEntityForm from '@/components/investigation/processCase/stepper/legalEntity/LegalEntityForm.vue'
import TaskList from '@/components/investigation/processCase/participants/TaskList.vue'
import EvaluationList from '@/components/investigation/processCase/participants/EvaluationList.vue'

export default {
  name: 'ParticipantStepper',
  components: {
    PhysicalPersonForm,
    LegalEntityForm,
    TaskList,
    EvaluationList
  },
  props: {
    participantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      savingInProgress: false,
      stepperState: 0,
      refreshLoading: false,
      confirmStepDialog: false,
      stepDialogLoading: false,
      apiError: null,
      confirmationText: null
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    participant () {
      return this.currentCase.participants.filter(p => p.id === this.participantId)[0]
    },
    someAreNotFinished () {
      let notFinished = false
      this.participant.investigations.forEach(i => {
        if (i.result.riskLevel < 0 || i.result.riskLevel > 2) {
          i.finished = false
          notFinished = true
        }
      })
      return notFinished
    }
  },
  watch: {
    'participant' (newValue, oldValue) {
      this.calcStepperState()
    }
  },
  mounted () {
    this.calcStepperState()
  },
  methods: {
    restartInvalidInvestigations () {
      if (this.someAreNotFinished) {
        this.stepDialogLoading = true
        this.savingInProgress = true
        this.confirmStep1()
      }
    },
    calcStepperState () {
      let result = 1
      if (this.participant.result.finalStatus && (this.participant.result.finalStatus === 'EVAL' || this.participant.result.finalStatus === 'FIN')) {
        result = 3
      } else if (this.participant.result.finalStatus && this.participant.result.finalStatus === 'INPC') {
        result = 2
      }
      this.stepperState = result
    },
    nextStep () {
      this.apiError = null
      this.stepDialogLoading = false
      this.confirmationText = null
      if (this.stepperState === 1) {
        if (this.$refs.personFormComponent.isFormValid()) {
          this.confirmationText = this.getConfirmationText()
          this.confirmStepDialog = true
        }
      }
      if (this.stepperState === 2) {
        if (this.checkAllTasksOk()) {
          this.confirmStepDialog = true
        } else {
          this.apiError = this.$i18n.t('$l.investigation.allTasksMustBeResolved')
          setTimeout(() => {
            this.apiError = null
          }, 3000)
        }
      }
      if (this.stepperState === 3) {
        let allNotesAdded = true
        this.participant.investigations.forEach(i => {
          if (i.result.userDefinedRiskLevel > -1 && !i.result.userDefinedLevelNote) {
            allNotesAdded = false
          }
        })
        if (allNotesAdded) {
          this.confirmStep()
        }
      }
    },
    confirmStep () {
      this.stepDialogLoading = true
      this.savingInProgress = true
      if (this.stepperState === 1) {
        this.confirmStep1()
      } else if (this.stepperState === 2) {
        this.confirmStep2()
      } else if (this.stepperState === 3) {
        this.confirmStep3()
      }
    },
    confirmStep1 () {
      var payload = {
        participant: this.participant,
        caseId: this.currentCase.caseId
      }
      this.$xapi.post('investigation/start', payload)
        .then(r => {
          this.stepperState = 2
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.$refs.taskList.startPooling()
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        }).finally(() => {
          this.confirmStepDialog = false
          this.savingInProgress = false
          this.stepDialogLoading = false
        })
    },
    confirmStep2 () {
      this.participant.result.finalStatus = 'EVAL'
      this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, this.participant)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
          this.data.investigation.result.resolved = false
        }).finally(() => {
          this.savingInProgress = false
          this.stepDialogLoading = false
          this.confirmStepDialog = false
        })
    },
    confirmStep3 () {
      if (this.participant.result.finalStatus === 'FIN') {
        this.cancelStepper()
      } else {
        this.cancelStepper()
        this.participant.result.finalStatus = 'FIN'
        this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, this.participant)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
          })
          .catch(e => {
            this.apiError = e.response.localizedMessage
            this.data.investigation.result.resolved = false
          }).finally(() => {
            this.savingInProgress = false
            this.stepDialogLoading = false
            this.confirmStepDialog = false
          })
      }
    },
    checkAllTasksOk () {
      let allTasksOk = true
      Array.from(this.participant.investigations, i => {
        allTasksOk = allTasksOk & i.result.resolved
      })
      return allTasksOk
    },
    cancelStepper () {
      this.$emit('cancel-stepper')
    },
    getConfirmationText () {
      if (this.dataComplete()) {
        return null
      }
      return this.$i18n.t('$l.app.afterConfirmNotComplete')
    },
    dataComplete () {
      if (this.participant.type === 'PhysicalPerson') {
        if (this.$refs.personFormComponent) {
          if (this.$refs.personFormComponent.formData) {
            var formData = this.$refs.personFormComponent.formData
            if (formData.subject &&
          (formData.birthDate || formData.birthNumber) &&
          formData.physicalPersonAddress &&
          formData.physicalPersonAddress.line1

            ) {
              if (this.$refs.personFormComponent.participant.flag !== 'UBO') {
                if (formData.idCardNumber && formData.idCardType) {
                  if (formData.entrepreneur) {
                    if (formData.businessId && formData.idCardType && formData.businessAddress && formData.businessAddress.line1) {
                      return true
                    } else {
                      return false
                    }
                  }
                  return true
                } else {
                  return false
                }
              }
              return true
            }
          }
        }
        return false
      } else {
        return true
      }
    }
  }
}
</script>
