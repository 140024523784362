<template>
  <v-card
    class="ma-0 pa-0"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.remark' | translate }}</span>
    </v-card-title>
    <v-card-text
      v-for="(item, index) in match.detail.remark"
      :key="index"
    >
      <div v-if="remarkIsObject(item)">
        <div v-if="JSON.parse(item).primaryPepReason">
          {{ $i18n.t('$l.case.remarkPepFor') + ': ' + JSON.parse(item).primaryPepReason.replace(/_/g, ' ') }}
        </div>

        <div v-if="JSON.parse(item).parentPep">
          {{ $i18n.t('$l.case.remarkLinkedToPep') + ': ' + JSON.parse(JSON.parse(item).parentPep).firstname + ' ' + JSON.parse(JSON.parse(item).parentPep).lastname + ', ' }}
          {{ $i18n.t('$l.case.remarkBornAsOf') + ': ' + JSON.parse(JSON.parse(item).parentPep).birthdate }}
        </div>
      </div>

      <div v-else>
        <div v-if="checkIfEmpty(item)">
          {{ item }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Remark',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      primarypepreason: null
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    },
    remarkIsObject (remark) {
      try {
        JSON.parse(remark)
        return true
      } catch (e) {
        return false
      }
    }
  }
}
</script>
