<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    save-button
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    @close-dialog="close()"
    @save-dialog="saveDialog"
  >
    <basic-inv-result :investigation="data.investigation" />

    <basic-note-hint
      class="mx-3"
    >
      {{ '$l.investigation.headerIDS' | translate }}
    </basic-note-hint>

    <app-api-call
      class="ma-0 pa-3 pb-0"
      :call-result="callResult"
    />

    <v-card-title>
      <v-radio-group
        v-model="confirmRadioGroup"
        :disabled="!callResult.finished"
        column
      >
        <v-radio
          :label="'$l.investigation.IdsConfirm' | translate"
          color="primary"
          :value="0"
          @change="scanAvailable"
        />
        <v-radio
          :label="'$l.investigation.IdsNotConfirm' | translate"
          color="error"
          :value="2"
          @change="scanNotAvailable"
        />
      </v-radio-group>
    </v-card-title>
  </basic-dialog>
</template>

<script>
export default {
  name: 'IdScanDialog',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      confirmRadioGroup: this.data.investigation.result.resolved ? this.data.investigation.result.riskLevel : -1,
      callResult: { finished: true, error: null, info: null },
      storedResult: {}
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.storedResult = JSON.parse(JSON.stringify(this.data.investigation.result))
  },
  methods: {
    close () {
      this.data.investigation.result = this.storedResult
      this.$emit('close-dialog')
    },
    saveDialog () {
      if (!(this.confirmRadioGroup >= 0)) {
        return
      }
      this.callResult.finished = false
      this.data.investigation.result.resolved = true
      this.data.investigation.result.riskLevel = this.confirmRadioGroup
      this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, this.data.participant)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.close()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
          this.data.investigation.result.resolved = false
          this.data.investigation.result.riskLevel = 2
        }).finally(() => {
          this.callResult.finished = true
        })
    },
    scanAvailable () {
      this.data.investigation.result.riskLevel = 0
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.IDScanAvailable' })
    },
    scanNotAvailable () {
      this.data.investigation.result.riskLevel = 2
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.IDScanNotAvailable' })
    }
  }
}
</script>
