<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    :save-button="actionAllowed"
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    :loading-save-button="loadingSaveButton"
    @close-dialog="close()"
    @save-dialog="saveDialog"
  >
    <basic-inv-result :investigation="data.investigation" />

    <template
      v-if="actionAllowed"
    >
      <basic-note-hint
        v-if="data.participant.type === 'PhysicalPerson'"
        class="ma-3"
      >
        {{ '$l.investigation.headerSPS' | translate }}
      </basic-note-hint>

      <app-api-call
        class="ma-0 pa-3 pb-0"
        :call-result="callResult"
      />

      <v-radio-group
        v-if="data.participant.type === 'PhysicalPerson'"
        v-model="confirmRadioGroup"
        :disabled="!actionAllowed"
        class="ma-3 pa-0"
        :error-messages="getErrorMessage()"
      >
        <v-card-title
          class="subtitle-1 d-table-cell okaml_break_word ma-0 pa-0"
        >
          {{ '$l.app.selectOne' | translate }}
        </v-card-title>
        <v-radio
          :label="$i18n.t('$l.investigation.FoWhoCallsOnHisBehalf')"
          color="primary"
          @change="foWhoCallsOnHisBehalf"
        />
        <v-radio
          :label="$i18n.t('$l.investigation.FoWhoActOnTheBasisOfPowerAttorney')"
          color="warning"
          @change="foWhoActOnTheBasisOfPowerAttorney"
        />
      </v-radio-group>
      <v-radio-group
        v-if="data.participant.type === 'LegalEntity'"
        v-model="confirmRadioGroup"
        :disabled="!actionAllowed"
        class="ma-3 pa-0"
        :error-messages="getErrorMessage()"
        @change="chengeConfirmRadioGroupLE"
      >
        <v-card-title
          class="subtitle-1 d-table-cell okaml_break_word ma-0 pa-0"
        >
          {{ '$l.app.selectOne' | translate }}
        </v-card-title>
        <v-radio
          :label="$i18n.t('$l.investigation.authorizedRepTransaction_0')"
          color="warning"
        />
        <v-radio
          :label="$i18n.t('$l.investigation.authorizedRepTransaction_1')"
          color="primary"
        />
        <v-radio
          :label="$i18n.t('$l.investigation.authorizedRepTransaction_2')"
          color="primary"
        />
      </v-radio-group>
      <AddParticipantForm
        v-show="(data.participant.type === 'LegalEntity' && confirmRadioGroup === 2) ||(data.participant.type === 'PhysicalPerson' && confirmRadioGroup === 1)"
        :new-participants="newParticipants"
        :hint="getHintForParticipantList()"
      />
    </template>

    <template
      v-else
    >
      <v-card-title>
        <span>{{ $i18n.t('$l.case.authorizedRepresentatives') }}</span>
        <v-container
          fluid
          class="ma-0 pa-0"
        >
          <div
            v-for="(p,idx) in getAuthorizedRepresentatives()"
            :key="idx"
          >
            <v-row>
              <v-col class="ma-1 pa-0">
                <v-card class="pa-0 px-2">
                  {{ p.name }}
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-title>
    </template>
    <template
      v-if="data.participant.type === 'LegalEntity'"
    >
      <v-divider />

      <v-subheader>
        {{ $i18n.t('$l.investigation.tipsToConsider') }}
      </v-subheader>

      <CompanyDetails :company-detail="companyDetail" />
    </template>
  </basic-dialog>
</template>

<script>
import AddParticipantForm from './common/AddParticipantForm.vue'
import CompanyDetails from './common/CompanyDetails.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AuthorizedRepresentativesDialog',
  components: {
    AddParticipantForm,
    CompanyDetails
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      loadingSaveButton: false,
      confirmRadioGroup: this.data.investigation.result.resolved ? this.data.investigation.result.selectedSPS : null,
      test: null,
      newParticipants: [],
      riskLevel: '',
      callResult: { finished: true, error: null, info: null },
      storedResult: {},
      companyDetail: {}
    }
  },
  validations: {
    confirmRadioGroup: { required },
    test: { required }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    actionAllowed () {
      return !this.data.participant.authorizedRepresentatives || this.data.participant.authorizedRepresentatives.length === 0
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.storedResult = JSON.parse(JSON.stringify(this.data.investigation.result))
    if (this.data.participant.type === 'LegalEntity') {
      if (this.data.participant.formData.businessId) {
        const ipo = this.data.participant.formData.businessId
        this.$xapi.get('endpointsSearchCompanies/businessId/' + ipo)
          .then(r => {
            this.companyDetail = r.data
          })
      }
    }
  },
  methods: {
    getErrorMessage () {
      if (this.$v.confirmRadioGroup.$dirty && !this.$v.confirmRadioGroup.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    close () {
      this.data.investigation.result = this.storedResult
      this.$emit('close-dialog')
    },
    saveDialog () {
      this.$v.confirmRadioGroup.$touch()
      if (!this.$v.confirmRadioGroup.$invalid) {
        if (this.data.participant.type === 'LegalEntity') {
          if (this.confirmRadioGroup === 2) {
            if (this.newParticipants.length > 0) { // must contains new participants
              this.updateParticipants()
            }
          } else {
            this.updateParticipants()
          }
        }
        if (this.data.participant.type === 'PhysicalPerson') {
          this.updateParticipants()
        }
      }
    },
    updateParticipants () {
      if (this.data.participant.type === 'PhysicalPerson' && this.confirmRadioGroup !== 1) {
        this.newParticipants = []
      }
      if (this.data.participant.type === 'LegalEntity' && this.confirmRadioGroup !== 2) {
        this.newParticipants = []
      }
      this.callResult.error = null
      this.$xapi.put(`investigation/processSPSTask?caseId=${this.currentCase.caseId}&participantId=${this.data.participant.id}&identificationValue=${this.confirmRadioGroup}`, this.newParticipants).then(r => {
        this.$store.commit('SET_CURRENT_CASE', r.data)
        this.close()
      }).catch(e => {
        this.callResult.error = e.response.localizedMessage
      }).finally(() => {
        this.loadingSaveButton = false
      })
    },
    getAuthorizedRepresentatives () {
      return this.currentCase.participants.filter(p => this.data.participant.authorizedRepresentatives.includes(p.id))
    },
    getHintForParticipantList () {
      if (this.data.participant.type === 'LegalEntity') {
        return this.$i18n.t('$l.hint.addAuthorizedRepForLegalEntity')
      } else if (this.data.participant.type === 'PhysicalPerson') {
        return this.$i18n.t('$l.hint.createParticipantList')
      }
      return null
    },
    foWhoCallsOnHisBehalf () {
      this.data.investigation.result.riskLevel = 0
      this.data.investigation.result.info = ''
    },
    foWhoActOnTheBasisOfPowerAttorney () {
      this.data.investigation.result.riskLevel = 1
      this.data.investigation.result.info = ''
    },
    chengeConfirmRadioGroupLE () {
      if (this.confirmRadioGroup === 0) {
        this.data.investigation.result.riskLevel = 1
      } else if (this.confirmRadioGroup === 1 || this.confirmRadioGroup === 2) {
        this.data.investigation.result.riskLevel = 0
        this.data.investigation.result.info = ''
      }
    }
  }
}
</script>
