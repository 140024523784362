<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
    max-height="500px"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.webLink' | translate }} {{ "("+ match.detail.link.length+ ")" }}</span>
    </v-card-title>

    <v-card-text
      v-for="(item, index) in match.detail.link"
      :key="index"
    >
      <a
        v-if="checkIfEmpty(item)"
        @click="openLink(item)"
      >
        {{ item }}
      </a>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WebLinks',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    },
    openLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .web-links {
    overflow-y: scroll;
  }
</style>
