<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <div
      v-for="match in matches"
      :key="match.id"
    >
      <SelectMatchDialog
        class="select-match"
        :match="match"
      />
    </div>
  </v-container>
</template>

<script>
import SelectMatchDialog from './SelectMatchDialog.vue'

export default {
  name: 'SelectMatch',
  components: {
    SelectMatchDialog
  },
  props: {
    matches: {
      type: Array,
      default: null
    }
  }
}
</script>
