import { render, staticRenderFns } from "./EveryPoliticianListDialog.vue?vue&type=template&id=7c5c2b54&"
import script from "./EveryPoliticianListDialog.vue?vue&type=script&lang=js&"
export * from "./EveryPoliticianListDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCardTitle,VCheckbox})
