<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row class="ma-0 pa-0 white--text borderBasicCaseData secondary lighten-1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            rounded
            style="top: 0px; right: 0px"
            class="ma-3 primary--text"
            dark
            @click="openUpdateDialog()"
            v-on="on"
          >
            <v-icon size="xx-large">
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.t('$l.app.edit') }}</span>
      </v-tooltip>
      <BasicCaseDataCol
        label="$l.case.name"
        :title="currentCase.name"
      />
      <BasicCaseDataCol
        v-if="currentCase.description"
        label="$l.case.description"
        :title="currentCase.description"
      />
      <BasicCaseDataCol
        v-if="currentCase.type === 'TransactionCase'"
        label="$l.case.transactionValue"
        :title="$n(currentCase.transactionValue, 'currency', currentCase.transactionCurrency)"
      />
      <BasicCaseDataCol
        v-if="currentCase.type === 'TransactionCase'"
        label="$l.case.currency"
        :title="currentCase.transactionCurrency"
      />
      <BasicCaseDataCol
        v-if="currentCase.type === 'TransactionCase'"
        label="$l.case.transactionDate"
        :title="currentCase.transactionPredictedDate"
      />
    </v-row>

    <v-row
      v-if="currentCase.type === 'TransactionCase' && (currentCase.transactionValue <= 0 || new Date(currentCase.transactionPredictedDate) < new Date())"
    >
      <v-col
        class="error--text"
      >
        <v-icon
          color="error"
          small
        >
          mdi-alert-outline
        </v-icon>
        {{ $i18n.t('$l.case.hintTransactionValueAndTransactionDate') }}
      </v-col>
    </v-row>

    <basic-dialog
      v-model="showDialog"
      max-width="600"
      title="$l.case.editCaseData"
      :loading-save-button="saveEditCaseForm"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="showDialog = false"
      @save-dialog="save()"
    >
      <v-card-text>
        <v-container
          fluid
          class="ma-0 pa-0"
        >
          <app-api-info
            :api-info="apiError"
          />
          <v-row>
            <v-col>
              <SimpleCaseForm
                ref="updateCaseComponent"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </basic-dialog>
  </v-container>
</template>

<script>
import BasicCaseDataCol from './BasicCaseDataCol.vue'
import SimpleCaseForm from './SimpleCaseForm.vue'

export default {
  name: 'BasicCaseData',
  components: {
    BasicCaseDataCol,
    SimpleCaseForm
  },
  data () {
    return {
      showDialog: false,
      saveEditCaseForm: false,
      apiError: null
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  methods: {
    openUpdateDialog () {
      this.showDialog = true
    },
    save () {
      let clonedCurrentCase = this.$refs.updateCaseComponent.isFormValid()
      if (clonedCurrentCase !== null) {
        this.saveEditCaseForm = true
        this.$xapi.put('case/update', clonedCurrentCase)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
            this.showDialog = false
          })
          .catch(e => {
            this.apiError = e.response.localizedMessage
          }).finally(() => {
            this.saveEditCaseForm = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .borderBasicCaseData {
    border-radius: 0.4em;
  }
</style>
