<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    @close-dialog="close()"
  >
    <basic-inv-result :investigation="data.investigation" />

    <v-card-title
      v-if="data.investigation.result.riskLevel==2 && data.participant.formData.idCardNumber"
    >
      <app-api-call
        class="ma-0 pa-3 pb-0"
        :call-result="callResult"
      />
      <v-checkbox
        v-model="confirmCheckbox"
        class="ma-0 pa-0"
        style="height:2em"
        :label="'$l.investigation.IDconfirm' | translate"
        @change="checkbox"
      />
    </v-card-title>
  </basic-dialog>
</template>

<script>
export default {
  name: 'StolenIdentityCardDialog',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      callResult: { finished: true, error: null, info: null }
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    confirmCheckbox: {
      get () {
        return this.data.investigation.result.resolved
      },
      set (val) {
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    checkbox () {
      this.data.investigation.result.resolved = !this.data.investigation.result.resolved
      this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, this.data.participant)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.close()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
          this.data.investigation.result.resolved = false
        }).finally(() => {
          this.disableRadioBtn = false
        })
    }
  }
}
</script>
