<template>
  <div>
    <v-avatar
      v-if="url"
      class="ma-1"
      color="greyee"
    >
      <v-img
        :src="url"
        @error="imageLoadOnError"
      />
    </v-avatar>

    <v-avatar
      v-else
      :color="avatarColor"
    >
      <v-icon
        size="55px"
      >
        mdi-account-circle
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    urls: {
      type: Array,
      default: null
    },
    avatarColor: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      url: '',
      index: 0
    }
  },
  watch: {
    urls () {
      this.index = 0
      this.url = this.urls.length > 0 ? this.urls[0] : null
    }
  },
  created () {
    this.url = this.urls.length > 0 ? this.urls[0] : null
  },
  methods: {
    imageLoadOnError () {
      // if first image cant be loaded, then go to next one if exist
      // #TODO: loop the image urls, until find the correct one
      if (this.urls.length > this.index + 1) {
        this.url = this.urls[this.index + 1]
      } else {
        this.url = null
      }
    }
  }
}
</script>
