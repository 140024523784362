<template>
  <div
    :id="'magic-grid-container-'+_uid"
    class="magic-grid-container"
  >
    <div v-if="checkIfEmpty(JSON.stringify(match.detail))">
      <Detail :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.image)">
      <ImageComp :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.age)">
      <Age :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.name)">
      <OtherKnownNames :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.membership)">
      <Membership :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.link)">
      <WebLinks :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.birth)">
      <BirthInfo :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.updates)">
      <Updates :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.citizenshipCountry)">
      <Citizenship :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.address)">
      <Address :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.entityId)">
      <EntityId :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.nationalityCountry)">
      <Nationality :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.companies)">
      <Companies :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.linkedTo)">
      <LinkedTo :match="match" />
    </div>
    <div v-if="checkIfEmpty(match.detail.remark)">
      <Remark :match="match" />
    </div>
  </div>
</template>
<script>
import MagicGrid from 'magic-grid'
import Detail from './matchdetail/Detail.vue'
import ImageComp from './matchdetail/ImageComp.vue'
import Age from './matchdetail/Age.vue'
import OtherKnownNames from './matchdetail/OtherKnownNames.vue'
import Membership from './matchdetail/Membership.vue'
import WebLinks from './matchdetail/WebLinks.vue'
import BirthInfo from './matchdetail/BirthInfo.vue'
import Updates from './matchdetail/Updates.vue'
import Citizenship from './matchdetail/Citizenship.vue'
import Address from './matchdetail/Address.vue'
import EntityId from './matchdetail/EntityId.vue'
import Nationality from './matchdetail/Nationality.vue'
import Companies from './matchdetail/Companies.vue'
import LinkedTo from './matchdetail/LinkedTo.vue'
import Remark from './matchdetail/Remark.vue'

export default {
  name: 'MatchDetail',
  components: { Detail, ImageComp, Age, OtherKnownNames, Membership, WebLinks, BirthInfo, Updates, Citizenship, Address, EntityId, Nationality, Companies, LinkedTo, Remark },
  props: {
    match: {
      type: Object,
      required: true
    },
    panel: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      magicGrid: Object
    }
  },
  computable () {
    return {
      countItems: 0
    }
  },
  created () {
    this.countItems = 0
  },
  updated () {
    this.countItems = 0
    this.$emit('content-updated')
  },
  methods: {
    initMagicGrid () {
      this.magicGrid = new MagicGrid({
        container: '#magic-grid-container-' + this._uid, // + '.magic-grid-container', // Required. Can be a class, id, or an HTMLElement.
        items: 1, // this.countVisibleItems(), // For a grid with 20 items. Required for dynamic content.
        static: false, // Required for static content. Default: false.
        gutter: 30, // Optional. Space between items. Default: 25(px).
        maxColumns: 5, // Optional. Maximum number of columns. Default: Infinite.
        useMin: true, // Optional. Prioritize shorter columns when positioning items. Default: false.
        useTransform: true, // Optional. Position items using CSS transform. Default: True.
        animate: true // Optional. Animate item positioning. Default: false.
      })
      this.magicGrid.listen()
    },
    checkIfEmpty (data) {
      return (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined)
    },
    positionItems () {
      this.magicGrid.positionItems()
    }
  }
}

</script>
<style lang="scss" scoped>
  .magic-grid-container > div {
    width: 300px;
    display: flex !important;
  }
  .magic-grid-container > div > div {
    width: 100%;
    border-radius: 10px !important;
    overflow-y: auto;
    max-height: 500px;
    height:max-content;
  }
</style>
