import { render, staticRenderFns } from "./ParticipantList.vue?vue&type=template&id=527d3af8&scoped=true&"
import script from "./ParticipantList.vue?vue&type=script&lang=js&"
export * from "./ParticipantList.vue?vue&type=script&lang=js&"
import style0 from "./ParticipantList.vue?vue&type=style&index=0&id=527d3af8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527d3af8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VList,VListItem,VListItemAvatar,VListItemGroup,VRow})
