<template>
  <v-form
    class="ma-0 pa-0"
  >
    <v-container
      fluid
      class="ma-0 pa-0"
    >
      <basic-note-hint>
        {{ '$l.investigation.legalEntityFormHint' | translate }}
      </basic-note-hint>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="formData.riskAreasOfBusiness"
            item-text="text"
            :items="riskAreasOfBusinessItems"
            :label="$i18n.t('$l.case.riskAreaOfBusiness')"
          />
        </v-col>

        <v-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <CompanyAutocomplete
            @on-selection="companySelected"
            @loading-address="setLoadingAddress"
          />
        </v-col>

        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-text-field
            v-model="formData.businessId"
            :label="$i18n.t('$l.company.businessId')"
            :error-messages="($v.formData.businessId.$dirty && !isTrueRequired(formData.businessId)) ? $i18n.t('$l.app.required') : ''"
            maxlength="10"
            counter
            @input="touchRequired('formData.businessId', formData.businessId)"
            @change="touchRequired('formData.businessId', formData.businessId)"
            @blur="touchRequired('formData.businessId', formData.businessId)"
          />
        </v-col>

        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-textarea
            v-model="formData.subject"
            :label="$i18n.t('$l.company.subject')"
            :error-messages="($v.formData.subject.$dirty && !isTrueRequired(formData.subject)) ? $i18n.t('$l.app.required') : ''"
            maxlength="224"
            counter
            rows="2"
            @input="touchRequired('formData.subject', formData.subject)"
            @change="touchRequired('formData.subject', formData.subject)"
            @blur="touchRequired('formData.subject', formData.subject)"
          />
        </v-col>

        <v-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-text-field
            :key="formKey"
            v-model="formData.address"
            :label="$i18n.t('$l.company.address')"
            :loading="loadingAddress"
            maxlength="224"
            counter
          />
        </v-col>

        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-autocomplete
            :key="$i18n.locale"
            v-model="formData.country"
            :items="$store.getters.getCountries"
            :label="$i18n.t('$l.company.country')"
            :filter="countryFilter"
            :item-value="item => item.iso3166_1"
            :item-text="'name'"
            :error-messages="($v.formData.country.$dirty && !isTrueRequired(formData.country)) ? $i18n.t('$l.app.required') : ''"
            @input="touchRequired('formData.country', formData.country)"
            @change="touchRequired('formData.country', formData.country)"
            @blur="touchRequired('formData.country', formData.country)"
          />
        </v-col>

        <v-col
          xl="4"
          lg="4"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        />
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CompanyAutocomplete from './CompanyAutocomplete.vue'

export default {
  name: 'LegalEntityForm',
  components: {
    CompanyAutocomplete
  },
  props: {
    participantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingAddress: false,
      formKey: 0,
      riskAreasOfBusinessItems: [
        { value: 'VICU', text: this.$i18n.t('$l.case.RAOB_VICU') },
        { value: 'PAIN', text: this.$i18n.t('$l.case.RAOB_PAIN') },
        { value: 'CUEX', text: this.$i18n.t('$l.case.RAOB_CUEX') },
        { value: 'OPGA', text: this.$i18n.t('$l.case.RAOB_OPGA') },
        { value: 'FOCU', text: this.$i18n.t('$l.case.RAOB_FOCU') },
        { value: 'PRME', text: this.$i18n.t('$l.case.RAOB_PRME') },
        { value: 'BUWE', text: this.$i18n.t('$l.case.RAOB_BUWE') },
        { value: 'PASE', text: this.$i18n.t('$l.case.RAOB_PASE') },
        { value: 'REAL', text: this.$i18n.t('$l.case.RAOB_REAL') },
        { value: 'TRAG', text: this.$i18n.t('$l.case.RAOB_TRAG') },
        { value: 'REBA', text: this.$i18n.t('$l.case.RAOB_REBA') },
        { value: 'OTHR', text: this.$i18n.t('$l.case.RAOB_OTHR') }
      ]
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    participant () {
      var participant = this.currentCase.participants.filter(p => p.id === this.participantId)[0]
      if (participant.flag === 'UBO') {
        participant.formData = {
          subject: participant.name
        }
      }
      return participant
    },
    formData () {
      return this.participant.formData
    }
  },
  created () {
    this.formData.riskAreasOfBusiness = 'OTHR'
  },
  validations: {
    formData: {
      subject: { required },
      businessId: { required },
      country: { required }
    }
  },
  methods: {
    setLoadingAddress (isLoadingAddress) {
      this.loadingAddress = isLoadingAddress
    },
    companySelected (company) {
      if (company) {
        this.resetFormData()
        this.formData.businessId = company.ico
        this.formData.subject = company.name
        this.formData.address = company.address
        this.formData.country = company.country
        this.formData.vatId = company.icdph
        this.formKey = Date.now()
      }
    },
    resetFormData () {
      this.formData.businessId = ''
      this.formData.subject = ''
      this.formData.address = ''
      this.formData.country = ''
      this.formData.vatId = ''
    },
    countryFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso3166_1.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textOne.normalize('NFD').replace(/[\u0300-\u036F]/g, '').indexOf(searchText) > -1
    },
    isFormValid () {
      this.$v.$touch()
      let valid = this.isTrueRequired(this.formData.businessId) && this.isTrueRequired(this.formData.subject) && this.isTrueRequired(this.formData.country)
      return valid
    },
    validateAndSave () {
      this.$emit('process-form')
    },
    isTrueRequired (val) {
      let valid = val && val.length > 0
      return valid
    },
    touchRequired (k, v) {
      if (k === 'formData.country') {
        if (this.isTrueRequired(v)) {
          this.$v.formData.country.$reset()
        } else {
          this.$v.formData.country.$touch()
        }
      }
      if (k === 'formData.subject') {
        if (this.isTrueRequired(v)) {
          this.$v.formData.subject.$reset()
        } else {
          this.$v.formData.subject.$touch()
        }
      }
      if (k === 'formData.businessId') {
        if (this.isTrueRequired(v)) {
          this.$v.formData.businessId.$reset()
        } else {
          this.$v.formData.businessId.$touch()
        }
      }
    }
  }
}
</script>

<style>
  .v-autocomplete__content .v-list-item {
    min-height: 24px;
  }
    .v-autocomplete__content .v-list-item__content {
    padding: 8px 0;
  }
  .v-autocomplete__content .theme--light.v-list-item .v-list-item__mask {
    background: #fff; color: rgba(0, 0, 0, 0.8);
  }
</style>
