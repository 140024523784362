<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    save-button
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    :loading-save-button="loadingSaveButton"
    @close-dialog="close()"
    @save-dialog="updateParticipants"
  >
    <basic-inv-result :investigation="data.investigation" />

    <basic-note-hint
      class="ma-3"
    >
      {{ $i18n.t('$l.investigation.headerUBO') }}
    </basic-note-hint>

    <app-api-call
      class="ma-0 pa-3 pb-0"
      :call-result="callResult"
    />

    <AddParticipantForm
      :new-participants="newParticipants"
    />

    <v-divider />

    <v-subheader>
      {{ $i18n.t('$l.investigation.tipsToConsider') }}
    </v-subheader>

    <CompanyDetails :company-detail="companyDetail" />
  </basic-dialog>
</template>

<script>
import AddParticipantForm from './common/AddParticipantForm.vue'
import CompanyDetails from './common/CompanyDetails.vue'

export default {
  name: 'UltimateBeneficialOwnerDialog',
  components: {
    AddParticipantForm,
    CompanyDetails
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    currentCase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      loadingSaveButton: false,
      participantListData: {
        selectedParticipantListData: [],
        addedParticipants: []
      },
      newParticipants: [],
      callResult: { finished: true, error: null, info: null },
      companyDetail: {}
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    if (this.data.investigation.result.info && JSON.parse(this.data.investigation.result.info).kuvs) {
      let backendParticipants = Array.from(JSON.parse(JSON.parse(this.data.investigation.result.info).kuvs))
      backendParticipants.forEach(p => {
        if (p.fullName && p.birthDate) {
          let newItem = { name: p.fullName, formData: { birthDate: p.birthDate }, type: p.type }
          this.newParticipants.push(newItem)
        }
        if (p.ipo) {
          let newItem = { name: `${p.companyName} (${p.ipo})`, type: p.type }
          this.newParticipants.push(newItem)
        }
      })
    }
    if (this.data.participant.formData.businessId) {
      const ipo = this.data.participant.formData.businessId
      this.$xapi.get('endpointsSearchCompanies/businessId/' + ipo)
        .then(r => {
          this.companyDetail = r.data
        })
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    updateParticipants () {
      this.loadingSaveButton = true
      this.currentCase.participants.forEach(p => {
        if (p.formData.subject) {
          this.newParticipants = this.newParticipants.filter(function (n) {
            return n.name !== p.formData.subject
          })
        }
      })
      this.$xapi.put(`investigation/processUboTask?caseId=${this.currentCase.caseId}&participantId=${this.data.participant.id}`, this.newParticipants).then(r => {
        this.$store.commit('SET_CURRENT_CASE', r.data)
        this.close()
      }).catch(e => {
        this.callResult.error = e.response.localizedMessage
      }).finally(() => {
        this.loadingSaveButton = false
      })
    }

  }
}
</script>
