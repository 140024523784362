<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    save-button
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    @close-dialog="close()"
    @save-dialog="saveDialog"
  >
    <basic-inv-result :investigation="data.investigation" />

    <basic-note-hint
      class="mx-3"
    >
      {{ '$l.investigation.mediaCheckHint1' | translate }}
    </basic-note-hint>

    <app-api-call
      class="ma-0 pa-3 pb-0"
      :call-result="callResult"
    />

    <v-card-title>
      <a
        class="ma-0 pa-0 pb-0"
        :href="'http://google.com/search?q=' + data.participant.formData.subject"
        target="_blank"
        @click="linkKlicked=true"
      >
        http://google.com/search?q={{ data.participant.formData.subject }}
      </a>
      <v-radio-group
        v-model="confirmRadioGroup"
        :disabled="!callResult.finished || !linkKlicked"
        column
        :error-messages="getErrorMessage()"
      >
        <v-radio
          :label="$i18n.t('$l.investigation.mediaCheckHasBeenDone')"
          color="primary"
          :value="0"
          @change="mediaCheckPerformed"
        />
        <v-radio
          :label="$i18n.t('$l.investigation.mediaCheckHasBeenNotDone')"
          color="error"
          :value="2"
          @change="mediaCheckNotPerformed"
        />
      </v-radio-group>
    </v-card-title>
  </basic-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'MediaCheckDialog',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      callResult: { finished: true, error: null, info: null },
      linkKlicked: true,
      confirmRadioGroup: this.data.investigation.result.resolved ? this.data.investigation.result.riskLevel : null,
      storedResult: {}
    }
  },
  validations: {
    confirmRadioGroup: { required }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.storedResult = JSON.parse(JSON.stringify(this.data.investigation.result))
  },
  methods: {
    getErrorMessage () {
      if (this.$v.confirmRadioGroup.$dirty && !this.$v.confirmRadioGroup.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    close () {
      this.data.investigation.result = this.storedResult
      this.$emit('close-dialog')
    },
    saveDialog () {
      this.$v.confirmRadioGroup.$touch()
      if (!this.$v.confirmRadioGroup.$invalid) {
        this.callResult.finished = false
        var participantCopy = JSON.parse(JSON.stringify(this.data.participant))
        var investigation = participantCopy.investigations.find(i => i.clientApi === 'MCH')
        investigation.result.resolved = true
        investigation.result.riskLevel = this.confirmRadioGroup
        this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, participantCopy)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
            this.close()
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          }).finally(() => {
            this.callResult.finished = true
          })
      }
    },
    mediaCheckPerformed () {
      this.data.investigation.result.riskLevel = 0
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.MCHconfirmed' })
    },
    mediaCheckNotPerformed () {
      this.data.investigation.result.riskLevel = 2
      this.data.investigation.result.info = JSON.stringify({ 'dialogInfo': '$l.dialog.MCHnotConfirmed' })
    }
  }
}
</script>
