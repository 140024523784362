<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    :save-button="!data.investigation.result.ok"
    @close-dialog="close()"
  >
    <basic-inv-result :investigation="data.investigation" />
  </basic-dialog>
</template>

<script>
export default {
  name: 'RiskAreaBusinessDialog',
  props: {
    data: {
      type: Object,
      required: true
    },
    currentCase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    }
  }
}
</script>
