<template>
  <v-container
    class="ma-0 pa-0"
  >
    <v-expansion-panels
      class="ma-0 pa-0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $i18n.t('$l.investigation.tipsCompanyPersons') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="(item, idx) in companyDetail.persons"
            :key="idx"
            class="body-1 mb-2 font-weight-medium"
          >
            {{ item.name }}
            <span
              class="body-2"
            >
              {{ item.address }}
            </span>
            <span
              class="body-2 font-weight-medium"
            >
              {{ item.function }}
            </span>
            <span
              class="body-2"
            >
              {{ item.functionFrom }}
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $i18n.t('$l.investigation.tipsAuthorization') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ companyDetail.authorization }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  name: 'CompanyDetails',
  props: {
    companyDetail: {
      type: Object,
      required: true
    }
  }
}
</script>
