<template>
  <div>
    <basic-dialog
      v-model="dialog"
      title="$l.case.newParticipant"
      :loading-save-button="savingInProgress"
      save-button
      save-button-label="$l.app.save"
      @close-dialog="close"
      @save-dialog="save()"
    >
      <app-api-call
        class="ma-0 pa-3 pb-0"
        :call-result="callResult"
      />

      <basic-note-hint
        class="ma-3"
      >
        {{ $i18n.t('$l.hint.addParticipantDialog') }}
      </basic-note-hint>

      <v-card-text>
        <v-form @submit.prevent="">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="participant.type"
                  :items="getParticipantTypeItems"
                  :label="$i18n.t('$l.case.personType')"
                  :error-messages="($v.participant.type.$dirty && !$v.participant.type.required) ? $i18n.t('$l.app.required') : ''"
                  :value="getParticipantTypeItems.length==1?participant.type=getParticipantTypeItems[0].value:null"
                  @input="$v.participant.type.$touch()"
                  @blur="$v.participant.type.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ }">
                    <v-text-field
                      v-model="participant.name"
                      :label="'$l.case.alias' | translate"
                      :error-messages="($v.participant.name.$dirty && !$v.participant.name.required) ? $i18n.t('$l.app.required') : ''"
                      maxlength="32"
                      counter
                      @input="$v.participant.name.$touch()"
                      @blur="$v.participant.name.$touch()"
                      @keydown.enter="save()"
                    />
                  </template>
                  <span>
                    {{ $i18n.t('$l.hint.addParticipantDialog2') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddParticipantDialog',
  props: {
    data: {
      type: Object,
      required: true
    },
    currentCase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      participantType: null,
      showSaveButton: false,
      savingInProgress: false,
      participant: this.data.participant,
      participantTypeItems: this.data.participantTypeItems,
      callResult: { finished: true, error: null, info: null }
    }
  },
  computed: {
    formTitle () {
      return this.participant.id ? this.$i18n.t('$l.case.updateParticipant') : this.$i18n.t('$l.case.newParticipant')
    },
    getParticipantTypeItems () {
      if (this.participantTypeItems) {
        return this.participantTypeItems
      } else {
        return [
          { text: this.$i18n.t('$l.case.PhysicalPerson'), value: 'PhysicalPerson' },
          { text: this.$i18n.t('$l.case.LegalEntity'), value: 'LegalEntity' }
        ]
      }
    }
  },
  validations: {
    participant: {
      name: { required },
      type: { required }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    save () {
      this.$v.$touch()
      if (this.isValid()) {
        this.saveParticipant(this.participant)
      }
    },
    saveParticipant (participant) {
      this.savingInProgress = true
      this.$xapi.put(`case/caseId/${this.currentCase.caseId}/participant/save`, participant)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.close()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.savingInProgress = false
        })
    },
    isValid () {
      this.$v.$touch()
      if (this.participant.type === 'LegalEntity') {
        return !this.$v.participant.$invalid
      } else {
        return !this.$v.participant.type.$invalid && !this.$v.participant.name.$invalid
      }
    }
  }
}
</script>
