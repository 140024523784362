<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    :save-button="actionAllowed"
    :loading-save-button="saveLegalRepresentative"
    @close-dialog="close()"
    @save-dialog="save()"
  >
    <basic-inv-result :investigation="data.investigation" />

    <div
      v-if="data.investigation.result.riskLevel==2"
      class="ma-3"
    >
      <basic-note-hint v-if="!confirmCheckbox">
        {{ $i18n.t('$l.hint.legalRepresentativeAdd') }}
      </basic-note-hint>
    </div>

    <v-row
      v-if="data.investigation.result.riskLevel==2 && !confirmCheckbox"
      class="ma-3"
    >
      <v-text-field
        v-model="participant.name"
        :error-messages="($v.participant.name.$dirty && !$v.participant.name.required) ? $i18n.t('$l.app.required') : ''"
        :label="$i18n.t('$l.person.subject')"
        maxlength="64"
        counter
        @input="$v.participant.name.$touch()"
        @blur="$v.participant.name.$touch()"
        @keydown.enter="save"
      />
    </v-row>

    <v-row
      v-if="data.investigation.result.riskLevel==2"
      class="ma-3 align-top"
    >
      <v-checkbox
        v-model="confirmCheckbox"
        class="ma-0 pa-0"
        :label="'$l.investigation.DoNotAddLegalRepresentative' | translate"
        :disabled="hasLegalRepresentative"
      />
      <span
        class="body-2 ml-2 float-right mt-1"
      >
        {{ '(' + $i18n.t('$l.investigation.riskLevelStaysHigh') + ')' }}
      </span>
    </v-row>

    <v-card-title v-if="hasLegalRepresentative">
      <span>{{ $i18n.t('$l.case.LegalRepresentatives') }}</span>
      <v-container
        fluid
        class="ma-0 pa-0"
      >
        <div
          v-for="(p,idx) in getLegalRepresentatives()"
          :key="idx"
        >
          <v-row>
            <v-col class="ma-1 pa-0">
              <v-card class="pa-0 px-2">
                {{ p.formData.subject }}
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-title>
  </basic-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'LegalRepresentativeDialog',
  props: {
    data: {
      type: Object,
      required: true,
      callResult: { finished: true, error: null, info: null }
    }
  },
  data () {
    return {
      dialog: true,
      showDialog: false,
      saveLegalRepresentative: false,
      participant: JSON.parse(JSON.stringify(this.$utils.participantDefault)),
      callResult: { finished: true, error: null, info: null },
      checkbox: null
    }
  },
  computed: {
    actionAllowed () {
      return (!this.data.participant.legalRepresentatives || this.data.participant.legalRepresentatives.length === 0) && this.data.investigation.result.riskLevel !== 0
    },
    hasLegalRepresentative () {
      return this.data.participant.legalRepresentatives && this.data.participant.legalRepresentatives.length > 0
    },
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    confirmCheckbox: {
      get () {
        if (this.checkbox === true || this.checkbox === false) { return this.checkbox }

        return this.data.investigation.result.doNotAddLegalRepresentative
      },
      set (val) {
        this.checkbox = val
      }
    }
  },
  validations: {
    participant:
    {
      name:
      { required }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    save () {
      this.$v.$touch()
      if (!this.$v.participant.name.$invalid || this.confirmCheckbox) {
        this.saveLegalRepresentative = true

        var payload = {
          participant: JSON.stringify(this.data.participant)
        }

        if (!this.confirmCheckbox) payload.lrName = this.participant.name
        this.$xapi.put(`investigation/processLRTask?caseId=${this.currentCase.caseId}`, payload).then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.close()
        }).catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.saveLegalRepresentative = false
        })
      }
    },
    getLegalRepresentatives () {
      return this.currentCase.participants.filter(p => this.data.participant.legalRepresentatives.includes(p.id))
    }
  }
}
</script>
