<template>
  <v-container
    fluid
  >
    <basic-123-stepper :step="2" />

    <app-api-call
      class="ma-0 pa-0 pb-0 mt-4"
      :call-result="callResult"
    />

    <CaseWrapper
      v-if="callResult.finished && callResult.authorized && caseNotClosed"
    />
  </v-container>
</template>

<script>
import CaseWrapper from '@/components/investigation/processCase/CaseWrapper.vue'

export default {
  name: 'ProcessCase',
  components: {
    CaseWrapper
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      caseNotClosed: true,
      loading: true
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.get('case/caseId/' + this.$route.params.caseId)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.callResult.authorized = true
          if (this.currentCase.status === 'CLOSED') {
            this.caseNotClosed = false
            this.callResult.info = this.$i18n.t('$l.error.movedToArchive')
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    }
  }
}
</script>
