<template>
  <v-container
    fluid
  >
    <v-form>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="currentCase.name"
            :label="$i18n.t('$l.case.name')"
            maxlength="64"
            counter
            :error-messages="($v.currentCase.name.$dirty && !$v.currentCase.name.required) ? $i18n.t('$l.app.required') : ''"
            @input="$v.currentCase.name.$touch()"
            @blur="$v.currentCase.name.$touch()"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="currentCase.description"
            :label="$i18n.t('$l.case.description')"
            maxlength="512"
            counter
            rows="3"
          />
        </v-col>
        <v-col
          v-if="currentCase.type === 'TransactionCase'"
          cols="12"
        >
          <v-text-field
            v-model="currentCase.transactionValue"
            type="number"
            maxlength="12"
            counter
            :label="$i18n.t('$l.case.transactionValue')"
            :error-messages="transactionValueErrorMessage(false)"
            @input="$v.currentCase.transactionValue.$touch()"
            @blur="$v.currentCase.transactionValue.$touch()"
          />
          <p v-if="currentCase.transactionValue">
            {{ $n(currentCase.transactionValue, 'currency', currentCase.transactionCurrency) }}
          </p>
        </v-col>
        <v-col
          v-if="currentCase.type === 'TransactionCase'"
          cols="12"
        >
          <v-select
            v-model="currentCase.transactionCurrency"
            :items="getCurrencyItems"
            :label="$i18n.t('$l.case.currency')"
            :error-messages="($v.currentCase.transactionCurrency.$dirty && !$v.currentCase.transactionCurrency.required) ? $i18n.t('$l.app.required') : ''"
            @input="$v.currentCase.transactionCurrency.$touch()"
            @blur="$v.currentCase.transactionCurrency.$touch()"
          />
        </v-col>
        <v-col
          v-if="currentCase.type === 'TransactionCase'"
          cols="12"
        >
          <basic-date-input
            v-model="currentCase.transactionPredictedDate"
            autocomplete="new-password"
            :value="currentCase.transactionPredictedDate"
            :label="$i18n.t('$l.case.transactionDate')"
            :hint="$i18n.t('$l.case.dateHint')"
            :transaction-date="true"
            :error-messages="transactionDateMessage()"
            @input="$v.currentCase.transactionPredictedDate.$touch()"
            @blur="$v.currentCase.transactionPredictedDate.$touch()"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'SimpleCaseForm',
  data () {
    return {
      currentCase: {}
    }
  },
  computed: {
    getCurrencyItems () {
      return [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ]
    }
  },
  created () {
    this.currentCase = JSON.parse(JSON.stringify(this.$store.getters.getCurrentCase))
  },
  validations: {
    currentCase: {
      name: { required },
      transactionValue: { required, maxLength: maxLength(12) },
      transactionCurrency: { required },
      transactionPredictedDate: { required }
    }
  },
  methods: {
    transactionValueErrorMessage (onContinue) {
      if (onContinue) {
        return 'too big number'
      } else {
        if (this.$v.currentCase.transactionValue.$dirty && !this.$v.currentCase.transactionValue.required) {
          return this.$i18n.t('$l.app.required')
        } else {
          return ''
        }
      }
    },
    transactionDateMessage () {
      if (this.$v.currentCase.transactionPredictedDate.$dirty && !this.$v.currentCase.transactionPredictedDate.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    isFormValid () {
      if (this.currentCase.caseType === 'TransactionCase') {
        if (this.$v.currentCase.name.$invalid || this.$v.currentCase.transactionValue.$invalid || this.$v.currentCase.transactionPredictedDate.$invalid) {
          return null
        }
      } else {
        if (this.$v.currentCase.name.$invalid) {
          return null
        }
      }
      return this.currentCase
    }
  }
}
</script>
