<template>
  <v-form
    ref="additionalInfoForm"
    lazy-validation
    class="ma-0 pa-0"
  >
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ $i18n.t('$l.person.addAditionalInfo') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="addItem(item)"
          >
            <v-list-item-title>{{ $i18n.t('$l.person.addInfo'+item.type) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-row>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <v-row>
          <v-col
            v-if="item.type==='OTHER'"
            xl="5"
            lg="5"
            md="5"
            sm="5"
            xs="12"
            cols="12"
          >
            <v-text-field
              v-model="item.otherValue"
              :outlined="outlinedAddressFields"
              :label="$i18n.t('$l.person.otherValue')"
              maxlength="64"
              counter
            />
          </v-col>
          <v-col
            :xl="item.type==='OTHER'?'6':'11'"
            :lg="item.type==='OTHER'?'6':'11'"
            :md="item.type==='OTHER'?'6':'11'"
            :sm="item.type==='OTHER'?'6':'11'"
            xs="11"
            cols="12"
          >
            <v-text-field
              v-model="item.value"
              :outlined="outlinedAddressFields"
              :label="$i18n.t('$l.person.addInfo'+item.type)"
              :maxlength="getMaxLength(item)"
              counter
            />
          </v-col>
          <v-col
            xl="1"
            lg="1"
            md="1"
            sm="1"
            xs="1"
            class="actions"
          >
            <v-icon
              medium
              color="darken-2"
              class="d-inline"
              @click="deleteItem(item, index)"
            >
              mdi-delete-forever-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'AdditionalInfoForm',
  props: {
    outlinedAddressFields: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

      items: [],
      menuItems: [
        {
          type: 'PHONE',
          value: '',
          otherValue: '' },
        {
          type: 'EMAIL',
          value: '',
          otherValue: '' },
        {
          type: 'EMPLOY',
          value: '',
          otherValue: '' },
        {
          type: 'OTHER',
          value: '',
          otherValue: '' }
      ]
    }
  },
  computed: {

  },
  validations: {

  },
  methods: {
    addItem (item) {
      this.items.push(JSON.parse(JSON.stringify(item)))
    },
    deleteItem (item, index) {
      this.items.splice(index, 1)
    },
    getMaxLength (item) {
      if (item.type === 'PHONE') {
        return 15
      } if (item.type === 'EMAIL') {
        return 64
      } else {
        return 128
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .actions {
      margin-left: -10px;
      padding: 0;
      margin-top: 36px;
  }
</style>
