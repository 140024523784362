<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    @close-dialog="close()"
  >
    <template>
      <v-card-title class="d-table-cell okaml_break_word">
        <v-list-item-avatar
          class="warning ma-0 pa-0 mr-2"
          size="30"
        >
          <v-icon
            class="white--text"
            v-text="'mdi-help'"
          />
        </v-list-item-avatar>
        <span>
          {{ '$l.investigation.repairInvestigationQuestion' | translate }}
        </span>
      </v-card-title>

      <v-card-title>
        <v-btn
          class="ml-2"
          @click="cancelInvestigation"
        >
          {{ '$l.investigation.repairInvestigationCancel' | translate }}
        </v-btn>
      </v-card-title>
    </template>
  </basic-dialog>
</template>

<script>
export default {
  name: 'RepairInvestigationDialog',
  props: {
    data: {
      type: Object,
      required: true
    },
    currentCase: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    }
  }
}
</script>
