<template>
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.address' | translate }}</span>
    </v-card-title>

    <v-card-text
      v-for="(item, index) in match.detail.address"
      :key="index"
    >
      <div v-if="checkIfEmpty(item.street)">
        {{ $i18n.t('$l.case.street') + ': ' + item.street }}
      </div>

      <div v-if="checkIfEmpty(item.number)">
        {{ $i18n.t('$l.case.number') + ': ' + item.number }}
      </div>

      <div v-if="checkIfEmpty(item.zip)">
        {{ $i18n.t('$l.case.zip') + ': ' + item.zip }}
      </div>

      <div v-if="checkIfEmpty(item.city)">
        {{ $i18n.t('$l.case.city') + ': ' + item.city }}
      </div>

      <div v-if="checkIfEmpty(item.country)">
        {{ $i18n.t('$l.case.country') + ': ' + item.country }}
      </div>

      <div v-if="checkIfEmpty(item.state)">
        {{ $i18n.t('$l.case.state') + ': ' + item.state }}
      </div>

      <div v-if="checkIfEmpty(item.other)">
        {{ $i18n.t('$l.case.other') + ': ' + item.other }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Address',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
