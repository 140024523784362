<template v-if="checkIfEmpty(match.detail.entityId)">
  <v-card
    class="ma-0 pa-0 matchdet"
    width="100%"
  >
    <v-card-title class="greyee">
      <span class="font-weight-medium">{{ '$l.case.entityId' | translate }}</span>
    </v-card-title>

    <v-card-text
      v-for="(item, index) in match.detail.entityId"
      :key="index"
    >
      <div v-if="checkIfEmpty(item.type)">
        {{ $i18n.t('$l.case.type') + ': ' + item.type }}
      </div>

      <div v-if="checkIfEmpty(item.number)">
        {{ $i18n.t('$l.case.number') + ': ' + item.number }}
      </div>

      <div v-if="checkIfEmpty(item.country)">
        {{ $i18n.t('$l.case.country') + ': ' + item.country }}
      </div>

      <div v-if="checkIfEmpty(item.issueDate)">
        {{ $i18n.t('$l.case.issueDate') + ': ' + item.issueDate }}
      </div>

      <div v-if="checkIfEmpty(item.expirationDate)">
        {{ $i18n.t('$l.case.expirationDate') + ': ' + item.expirationDate }}
      </div>

      <div v-if="checkIfEmpty(item.city)">
        {{ $i18n.t('$l.case.city') + ': ' + item.city }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'EntityId',
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkIfEmpty (data) {
      if (data && data.length > 0 && data !== 'null' && data !== 'unknown' && data !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
