<template>
  <basic-dialog
    v-model="dialog"
    :title="data.title"
    :max-width="data.investigation.result.matches.length===0 ? '500' : '1200'"
    :save-button="data.investigation.result.matches.length!==0 || data.investigation.result.unsearchable"
    :save-button-label="$i18n.t('$l.app.confirm')"
    save-button-color="primary"
    :loading-save-button="loadingSaveButton"
    @close-dialog="close()"
    @save-dialog="save()"
  >
    <basic-inv-result :investigation="data.investigation" />

    <app-api-call
      class="ma-0 pa-3 pb-0"
      :call-result="callResult"
    />

    <basic-note-hint
      v-if="data.investigation.result.matches.length!==0"
      class="mx-3"
    >
      {{ $i18n.t('$l.hint.dbMatchDialog') }}
    </basic-note-hint>

    <v-card-title
      v-if="data.investigation.result.matches.length!==0"
    >
      <SelectMatch :matches="data.investigation.result.matches" />
    </v-card-title>
  </basic-dialog>
</template>

<script>
import SelectMatch from './../caseResult/SelectMatch.vue'

export default {
  name: 'EuropeanSanctionListDialog',
  components: {
    SelectMatch
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      loadingSaveButton: false,
      callResult: { finished: true, error: null, info: null }
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    close () {
      this.$emit('close-dialog')
    },
    save () {
      this.loadingSaveButton = true
      this.$xapi.put(`investigation/procesSanctionListTask?caseId=${this.currentCase.caseId}&dbs=USDN`, this.data.participant)
        .then(r => {
          this.$store.commit('SET_CURRENT_CASE', r.data)
          this.close()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.loadingSaveButton = false
        })
    }
  }
}
</script>
