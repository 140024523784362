<template>
  <div>
    <basic-note-hint
      class="mb-3"
    >
      {{ $i18n.t('$l.hint.evaluationListHint') }}
    </basic-note-hint>

    <v-list
      two-line
      class="ma-0 pa-0"
      shaped
    >
      <app-api-info
        :api-info="checkAllNotesAdded()"
        class="ma-0"
      />

      <v-list-item
        v-for="investigation in participant.investigations"
        :key="investigation.clientApi"
        class="greyee task_list mb-1"
        @click="listItemClick(investigation)"
      >
        <v-container
          fluid
          class="ma-0 pa-0"
        >
          <v-row
            class="ma-0 pa-0"
          >
            <v-col
              class="ma-0 pa-0 d-flex align-center"
              xl="5"
              lg="5"
              md="12"
              sm="12"
              xs="12"
              cols="12"
            >
              <v-list-item-content>
                <v-card-text class="subtitle-1 font-weight-medium ma-0 pa-0 mt-3">
                  {{ `$l.caseinv.${investigation.clientApi}` | translate }}
                </v-card-text>
              </v-list-item-content>
            </v-col>

            <v-col
              class="ma-0 pa-0 d-flex align-center"
              xl="5"
              lg="5"
              md="8"
              sm="12"
              xs="12"
              cols="12"
            >
              <v-list-item-content>
                <v-card-text class="subtitle-1 font-weight-regular ma-0 pa-0">
                  {{ $i18n.t('$l.case.recommendedRiskLevel') }}
                  <span :class="computeColorClass(true, investigation.result, '--text darken-1')">{{ $i18n.t(`$l.case.level${investigation.result.riskLevel}`) }}</span>
                </v-card-text>
                <v-card-text
                  class="subtitle-1 font-weight-regular ma-0 pa-0"
                >
                  {{ $i18n.t('$l.case.userDefinedRiskLevel') }}
                  <span :class="computeColorClass(false, investigation.result, '--text darken-1')">{{ $i18n.t(`$l.case.level${investigation.result.userDefinedRiskLevel}`) }}</span>
                </v-card-text>
                <v-text-field
                  v-if="investigation.result.userDefinedLevelNote || investigation.result.userDefinedRiskLevel >= 0"
                  v-model="investigation.result.userDefinedLevelNote"
                  class="mr-3"
                  :class="{ 'error lighten-3': !investigation.result.userDefinedLevelNote }"
                  :disabled="showNoteDialog"
                  :label="$i18n.t('$l.case.definedRiskLevelNote')"
                  maxlength="128"
                  counter
                  @click="openShowNoteDialog(investigation)"
                />
              </v-list-item-content>
            </v-col>

            <v-col
              class="ma-0 pa-0 d-flex align-center"
              xl="2"
              lg="2"
              md="4"
              sm="6"
              xs="6"
              cols="12"
            >
              <v-list-item-content>
                <v-select
                  ref="selRiskLevel"
                  :items="levelRiskItems"
                  :item-text="item => item.text"
                  :item-value="item => item.value"
                  :label="'$l.case.adjustRiskLevel' | translate"
                  @change="saveUserDefinedLevel"
                />
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-list>

    <basic-dialog
      v-model="showNoteDialog"
      title="$l.case.definedRiskLevelNote"
      :loading-save-button="saveNoteProgress"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="closeNoteDialog()"
      @save-dialog="saveNote()"
    >
      <v-card-text>
        <v-container fluid>
          <v-form
            ref="noteForm"
          >
            <v-textarea
              v-model="note.description"
              :label="$i18n.t('$l.case.addStatement')"
              :error-messages="($v.note.description.$dirty && !$v.note.description.required) ? $i18n.t('$l.app.required') : ''"
              @input="$v.note.description.$touch()"
              @blur="$v.note.description.$touch()"
            />
          </v-form>
        </v-container>
      </v-card-text>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'EvaluationList',
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      note: {
        title: '',
        description: '',
        created: null
      },
      investigationSelected: null,
      selectedValue: null,
      selectedRiskLevel: '',
      showNoteDialog: false,
      saveNoteProgress: false,
      levelRiskItems: [
        {
          text: this.$i18n.t('$l.case.level0'), value: 0
        },
        {
          text: this.$i18n.t('$l.case.level1'), value: 1
        },
        {
          text: this.$i18n.t('$l.case.level2'), value: 2
        }
      ]
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    }
  },
  validations: {
    note: {
      description: { required }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.participant.investigations.forEach(i => {
        if (i.result.notes && i.result.notes[0]) {
          i.result.userDefinedLevelNote = i.result.notes[0].description
        }
      })
    },
    listItemClick (investigation) {
      this.investigationSelected = investigation
    },
    computeColorClass (reccomended, result, append) {
      let color = 'label'
      let level = reccomended ? result.riskLevel : result.userDefinedRiskLevel
      if (level === 0) {
        color = 'success'
      }
      if (level === 1) {
        color = 'warning'
      }
      if (level === 2) {
        color = 'error'
      }
      return color + append
    },
    saveUserDefinedLevel (e) {
      this.selectedValue = e
      this.showNoteDialog = true
    },
    openShowNoteDialog (i) {
      this.investigationSelected = i
      this.showNoteDialog = true
    },
    closeNoteDialog () {
      this.showNoteDialog = false
      this.note.description = ''
      this.$refs.noteForm.reset()
      this.$refs.selRiskLevel.forEach(s => {
        s.reset()
      })
      this.selectedValue = null
    },
    resetNote () {
      this.note.title = ''
      this.note.description = ''
      this.note.date = ''
    },
    saveNote () {
      this.$v.$touch()
      if (!this.$v.note.description.$invalid) {
        this.saveNoteProgress = true
        this.note.created = new Date().toISOString()
        this.investigationSelected.result.notes = []
        this.investigationSelected.result.notes.push({ ...JSON.parse(JSON.stringify(this.note)) })
        if (this.selectedValue !== null) {
          this.investigationSelected.result.userDefinedRiskLevel = this.selectedValue
        }
        this.$xapi.put(`case/updateParticipant?caseId=${this.currentCase.caseId}`, this.participant)
          .then(r => {
            this.$store.commit('SET_CURRENT_CASE', r.data)
          })
          .catch(e => {
          }).finally(() => {
            this.saveNoteProgress = false
            this.showNoteDialog = false
            this.resetNote()
            this.selectedValue = null
            this.init()
          })
      }
    },
    checkAllNotesAdded () {
      let allNotesAdded = true
      this.participant.investigations.forEach(i => {
        if (i.result.userDefinedRiskLevel > -1 && !i.result.userDefinedLevelNote) {
          allNotesAdded = false
        }
      })
      if (allNotesAdded) {
        return null
      } else {
        return this.$i18n.t('$l.investigation.someNotesAreMissing')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .task_list.v-list-item .v-list-item__action {
      margin: 0px;
      padding: 0px;
  }
  .task_list.v-list-item .v-list-item__content {
      margin: 0px;
      padding: 0px;
  }
  .task_list.v-list-item .v-list-item__avatar {
      margin: 0em 1em 0em 0em;
      padding: 0px;
  }
</style>
