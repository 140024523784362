<template>
  <v-form
    ref="physicalPersonForm"
    class="ma-0 pa-0"
  >
    <v-container
      fluid
      class="ma-0 pa-0"
    >
      <basic-note-hint>
        {{ '$l.case.physPersonFormToolTip1' | translate }}
      </basic-note-hint>

      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formData.subject"
                type="text"
                :error-messages="personNameErrorMessage()"
                :label="$i18n.t('$l.person.subject')"
                maxlength="64"
                counter
                v-on="on"
                @input="$v.formData.subject.$touch()"
                @blur="$v.formData.subject.$touch()"
              />
            </template>
            <span>
              {{ '$l.case.physPersonFormToolTip2' | translate }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="!isSPS"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-autocomplete
            :key="$i18n.locale"
            v-model="formData.country"
            :items="$store.getters.getCountries"
            :label="$i18n.t('$l.person.country')"
            :filter="countryFilter"
            :item-value="item => item.iso3166_1"
            :item-text="'name'"
            :error-messages="($v.formData.country.$dirty && !$v.formData.country.required) ? $i18n.t('$l.app.required') : ''"
            @input="$v.formData.country.$touch()"
            @blur="$v.formData.country.$touch()"
          />
        </v-col>

        <v-col
          :xl="showBirthNumber?'3':'6'"
          :lg="showBirthNumber?'3':'6'"
          :md="showBirthNumber?'3':'6'"
          :sm="showBirthNumber?'3':'6'"
          xs="12"
          cols="12"
        >
          <basic-date-input
            v-model="formData.birthDate"
            :value="formData.birthDate"
            :label="$i18n.t('$l.person.birthDate')"
            :hint="$i18n.t('$l.case.dateHint')"
            :transaction-date="false"
            :error-messages="birthDateErrorMessage()"
            @input="$v.formData.birthDate.$touch()"
            @blur="$v.formData.birthDate.$touch()"
          />
        </v-col>

        <v-col
          v-if="showBirthNumber"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="12"
          cols="12"
        >
          <v-text-field
            v-model="formData.birthNumber"
            :label="$i18n.t('$l.person.birthNumber')"
            @input="$v.formData.businessId.$touch()"
            @blur="$v.formData.businessId.$touch()"
          />
        </v-col>
        <v-col
          v-if="!isUBO"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="12"
          cols="12"
        >
          <v-select
            :key="$i18n.locale"
            v-model="formData.idCardType"
            :items="idCardTypes"
            :label="$i18n.t('$l.person.idCardType')"
          />
        </v-col>
        <v-col
          v-if="!isUBO"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="12"
          cols="12"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formData.idCardNumber"
                maxlength="15"
                counter
                :label="$i18n.t('$l.person.idCardNumber')"
                v-on="on"
              />
            </template>
            <span>
              {{ '$l.case.physPersonFormToolTip5' | translate }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="!isUBO && !isSPS"
          :xl="formData.entrepreneur?'6':'12'"
          :lg="formData.entrepreneur?'6':'12'"
          :md="formData.entrepreneur?'6':'12'"
          :sm="formData.entrepreneur?'6':'12'"
          xs="12"
          cols="12"
        >
          <v-checkbox
            v-model="formData.entrepreneur"
            :label="$i18n.t('$l.person.physPersonBusines')"
            color="success"
            hide-details
          />
        </v-col>
        <v-col
          v-if="formData.entrepreneur"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="12"
          cols="12"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formData.businessId"
                :label="$i18n.t('$l.person.businessId')"
                :error-messages="getBusinessIdErrorMsg()"
                @input="$v.formData.businessId.$touch()"
                @blur="$v.formData.businessId.$touch()"
                @keydown.enter="validateAndSave()"
                v-on="on"
              />
            </template>
            <span>
              {{ '$l.case.physPersonFormToolTip6' | translate }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-expansion-panels
            class="ma-0 pa-0 mt-4"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $i18n.t('$l.person.pernamentAdress') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                eager
              >
                <AddressForm
                  ref="addressForm"
                  :outlined-address-fields="false"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="formData.entrepreneur"
            >
              <v-expansion-panel-header>
                {{ $i18n.t('$l.person.businessAddress') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                eager
              >
                <AddressForm
                  ref="businessAddressForm"
                  :outlined-address-fields="false"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $i18n.t('$l.person.additionalInfo') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                eager
              >
                <AdditionalInfoForm
                  ref="additionalInfoForm"
                  :outlined-address-fields="false"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { required, helpers, numeric } from 'vuelidate/lib/validators'
import AddressForm from './AddressForm.vue'
import AdditionalInfoForm from './AdditionalInfoForm.vue'
const personNameValidation = helpers.regex('alpha', /^[^!@#&()[{}\]:;,?/*]{2,}\s[^!@#&()[{}\]:;,?/*]{2,}$/)

export default {
  name: 'PhysicalPersonForm',
  components: {
    AddressForm,
    AdditionalInfoForm
  },
  props: {
    participantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      outlinedAddressFields: true
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    showBirthNumber () {
      return this.formData.country === 'SK' || this.formData.country === 'CZ'
    },
    participant () {
      var participant = this.currentCase.participants.filter(p => p.id === this.participantId)[0]
      if (participant.flag === 'UBO') {
        participant.formData = {
          subject: participant.name,
          birthDate: participant.formData.birthDate
        }
      }
      return participant
    },
    formData () {
      return this.participant.formData
    },
    isUBO () {
      return this.participant.flag === 'UBO'
    },
    isSPS () {
      return this.participant.flag === 'SPS'
    },
    idCardTypes () {
      return [
        { 'value': 'IDCARD', 'text': this.$i18n.t('$l.person.typeIdCard') },
        { 'value': 'PASSPO', 'text': this.$i18n.t('$l.person.typePassport') },
        { 'value': 'DRIVLI', 'text': this.$i18n.t('$l.case.driverLicenceID') },
        { 'value': 'INSURN', 'text': this.$i18n.t('$l.case.insuranceNumber') }
      ]
    }
  },
  validations: {
    formData: {
      subject: { required, personNameValidation },
      country: { required },
      birthDate: { required },
      businessId: {
        numeric,
        businessIdLengthConstrain (val) {
          return (val && (val.length === 8)) || val === undefined || val === ''
        }
      }
    }
  },
  methods: {
    countryFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso3166_1.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textOne.normalize('NFD').replace(/[\u0300-\u036F]/g, '').indexOf(searchText) > -1
    },
    isFormValid () {
      this.$v.$touch()
      if (this.$refs.addressForm) {
        this.formData.physicalPersonAddress = this.$refs.addressForm.getAddress()
      }
      if (this.$refs.additionalInfoForm) {
        this.formData.additionalInfo = this.$refs.additionalInfoForm.items
      }

      if (this.isSPS) { // in this case its required to specify only visibe fields for validation
        return this.isValidSPS()
      } else if (this.isUBO) { // in this case its required to specify only visibe fields for validation
        return this.isValidUBO()
      } else {
        return this.isValidAll()
      }
    },
    isValidSPS () {
      if (this.$refs.addressForm.validateAddressForm() && !this.$v.formData.subject.$invalid && !this.$v.formData.birthDate.$invalid) {
        return true
      } else {
        return false
      }
    },
    isValidUBO () {
      if (this.formData.entrepreneur) {
        this.formData.businessAddress = this.$refs.businessAddressForm.getAddress()
        if (this.$refs.businessAddressForm.validateAddressForm() && this.$refs.addressForm.validateAddressForm() &&
            !this.$v.formData.subject.$invalid &&
            !this.$v.formData.country.$invalid &&
            !this.$v.formData.birthDate.$invalid) {
          return true
        }
      } else {
        if (this.$refs.addressForm.validateAddressForm() &&
            !this.$v.formData.subject.$invalid &&
            !this.$v.formData.country.$invalid &&
            !this.$v.formData.birthDate.$invalid) {
          return true
        }
      }
      return false // valid === 1
    },
    isValidAll () {
      if (this.formData.entrepreneur) {
        this.formData.businessAddress = this.$refs.businessAddressForm.getAddress()
        if (this.$refs.businessAddressForm.validateAddressForm() && this.$refs.addressForm.validateAddressForm() && !this.$v.formData.$invalid) {
          return true
        }
      } else {
        if (this.$refs.addressForm.validateAddressForm() && !this.$v.formData.$invalid) {
          return true
        }
      }
      return false
    },
    validateAndSave () {
      this.$emit('process-form')
    },
    birthDateErrorMessage () {
      if (this.$v.formData.birthDate.$dirty && !this.$v.formData.birthDate.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    personNameErrorMessage () {
      var message = ''
      if (this.$v.formData.subject.$dirty && !this.$v.formData.subject.required) {
        message = this.$i18n.t('$l.app.required')
      }
      if (this.$v.formData.subject.$dirty && !this.$v.formData.subject.personNameValidation) {
        message = this.$i18n.t('$l.tooltip.personName')
      }
      return message
    },
    getBusinessIdErrorMsg () {
      var message = ''
      if (this.$v.formData.businessId.$model !== undefined) {
        if (this.$v.formData.businessId.$dirty && !this.$v.formData.businessId.numeric) {
          message = this.$i18n.t('$l.app.validationNumeric')
        } else if (this.$v.formData.businessId.$dirty && !this.$v.formData.businessId.businessIdLengthConstrain) {
          message = this.$i18n.t('$l.app.validationLength8digits')
        }
      }
      return message
    }
  }
}
</script>
