<template>
  <div>
    <v-list
      v-bind="$attrs"
      two-line
      class="ma-0 pa-0"
      shaped
      :disabled="participantSelectedId.length > 0"
    >
      <v-list-item-group
        v-model="selected"
        color="primary"
      >
        <v-list-item
          v-for="(participant, idx) in currentCase.participants"
          :key="idx"
          class="mb-1 participant_list greyee"
          active-class="secondary--text"
          @click="$emit('participant-selected', participant)"
        >
          <v-list-item-avatar>
            <v-icon
              class="info1"
              dark
            >
              {{ participantIcon(participant) }}
            </v-icon>
          </v-list-item-avatar>
          <v-row
            class="justify-end"
          >
            <div
              v-if="participantSelectedId.length === 0"
              class=""
              style="width: 100%;"
            >
              <div
                class="ma-1 ml-2 pa-0 justify-start float-left pb-0"
                style="line-height: 20px;"
              >
                <div
                  class="body-1 font-weight-medium label--text"
                >
                  <div v-if="participant.isLegalRepresentative">
                    {{ $i18n.t('$l.process.typeLegalRepresentative') }}
                  </div>
                  <div v-else-if="participant.flag==='SPS'">
                    {{ $i18n.t('$l.process.typeAccreditedRepresentative') }}
                  </div>
                  <div v-else-if="participant.flag==='UBO'">
                    {{ $i18n.t('$l.process.typeUbo') }}
                  </div>
                  <div v-else>
                    {{ $i18n.t('$l.process.typePrimarySubject') }}
                    <span
                      class="font-weight-regular"
                    >
                      {{ '(' + $i18n.t(`$l.case.${participant.type}`) + ')' }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="currentCase.type === 'TransactionCase'"
                  class=""
                  style="font-size: 18px"
                >
                  <span v-if="participant.formData && participant.formData.subject && participant.name && participant.name !== participant.formData.subject">
                    {{ participant.name + ' - ' + participant.formData.subject }}
                  </span>
                  <span v-if="participant.name && !participant.formData.subject">{{ participant.name }}</span>
                  <span v-if="participant.formData && participant.formData.subject && participant.name && participant.name === participant.formData.subject">
                    {{ participant.formData.subject }}
                  </span>
                </div>
                <div
                  v-if="currentCase.type !== 'TransactionCase' && participant.formData && participant.formData.subject"
                  class=""
                  style="font-size: 18px"
                >
                  {{ participant.formData.subject }}
                </div>
              </div>
              <div
                class="ma-1 pa-0 justify-end float-right text-h6 font-weight-regular accent--text text--darken-1 mr-5"
                style="line-height: 20px;"
              >
                {{ `$l.case.${participant.result.finalStatus}` | translate }}
              </div>
            </div>
          </v-row>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'ParticipantList',
  props: {
    participantSelectedId: {
      type: String,
      default: ''
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    selected: {
      get () {
        let selectedIndex = -1
        if (this.participantSelectedId) {
          for (let index = 0; index < this.currentCase.participants.length; index++) {
            if (this.currentCase.participants[index].id === this.participantSelectedId) {
              selectedIndex = index
            }
          }
        }
        return selectedIndex
      },
      set (val) {
      }
    }
  },
  methods: {
    participantIcon (participant) {
      if (participant.type === 'LegalEntity') {
        return 'mdi-account-multiple'
      }
      return 'mdi-account'
    }
  }
}
</script>

<style lang="scss" scoped>
  .participant_list.v-list-item .v-list-item__action {
      margin: 0px;
      padding: 0px;
  }
  .participant_list.v-list-item .v-list-item__content {
      margin: 0px;
      padding: 0px;
  }
  .participant_list.v-list-item .v-list-item__avatar {
      margin: 0em 1em 0em 0em;
      padding: 0px;
  }
</style>
