<template slot>
  <div>
    <basic-note-hint
      v-if="hint!==null"
      class="ma-3"
    >
      {{ $i18n.t(hint) }}
    </basic-note-hint>
    <v-row
      class="ma-0 pa-0"
    >
      <v-col
        class="d-inline-flex"
      >
        <v-text-field
          v-model="newParticipant"
          :label="$i18n.t('$l.person.subject')"
          outlined
          clearable
          dense
          maxlength="64"
          counter
          :error-messages="personNameErrorMessage()"
          @keydown.enter="addNewParticipant"
          @input="$v.newParticipant.$touch()"
          @blur="$v.newParticipant.$touch()"
        />
        <v-btn
          class="ma-2 mr-0 mt-0"
          :disabled="!$v.newParticipant.personNameValidation || !(newParticipant.length > 0)"
          tile
          outlined
          color="primary"
          @click="addNewParticipant"
        >
          <v-icon left>
            mdi-plus
          </v-icon>{{ $i18n.t('$l.app.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-list
      v-if="showList"
      subheader
      flat
    >
      <v-divider />

      <v-subheader>
        {{ $i18n.t('$l.investigation.addSPSPerson') }}
      </v-subheader>

      <v-list-item-group
        multiple
      >
        <v-list-item
          v-for="(p, i) in newParticipants"
          :key="i"
          class="kuv_list_item"
        >
          <v-list-item-icon>
            <v-icon v-text="'mdi-account'" />
          </v-list-item-icon>
          <v-list-item-content class="ma-0 pa-0">
            <v-list-item-title class="subtitle-1 ma-0 pa-0">
              {{ p.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="p.birthDate"
            >
              {{ p.birthDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              @click="removeFromList(i)"
            >
              mdi-delete-forever
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { helpers } from 'vuelidate/lib/validators'
const personNameValidation = helpers.regex('alpha', /^[^!@#&()[{}\]:;,?/*]{2,}\s[^!@#&()[{}\]:;,?/*]{2,}$/)
export default {
  name: 'AddParticipantForm',
  props: {
    newParticipants: {
      type: Array,
      default: () => []
    },
    hint: {
      type: String,
      default: null
    }

  },
  data () {
    return {
      newParticipant: ''
    }
  },
  computed: {
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    showList () {
      return this.newParticipants.length > 0
    }
  },
  validations: {
    newParticipant: { personNameValidation }
  },
  methods: {
    toggleSelection (participant) {
      participant.isSelected = !participant.isSelected
    },
    close () {
      this.$emit('close-dialog')
    },

    addNewParticipant () {
      if (!(this.newParticipant && this.newParticipant.length > 0)) {
        return
      }
      if (this.$v.newParticipant.personNameValidation) {
        this.newParticipants.push({
          name: this.newParticipant,
          functionDescription: 'n/a'
        })
        this.newParticipant = ''
      }
    },
    removeFromList (index) {
      this.newParticipants.splice(index, 1)
    },
    personNameErrorMessage () {
      var message = ''
      if (this.$v.newParticipant.$dirty && !this.$v.newParticipant.personNameValidation) {
        message = this.$i18n.t('$l.tooltip.personName')
      }
      return message
    }
  }
}
</script>

<style lang="scss" scoped>
  .kuv_list_item.v-list-item {
    min-height: 2em;
  }
</style>
