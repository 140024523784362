<template>
  <div>
    <basic-note-hint>
      {{ $i18n.t('$l.hint.taskList') }}
    </basic-note-hint>

    <v-card-actions>
      <span class="d-flex font-weight-medium subtitle-1">{{ '$l.case.investigationList' | translate }}</span>
      <v-spacer />
      <span class="d-flex font-weight-medium subtitle-1">{{ '$l.case.riskLevel' | translate }}</span>
    </v-card-actions>

    <v-list
      two-line
      class="ma-0 pa-0 task_list"
      shaped
    >
      <transition-group
        tag="ul"
        name="list"
      >
        <v-list-item
          v-for="investigation in sortedParticipantInvestigations"
          :key="investigation.clientApi"
          class="greyee task_list mb-1"
          @click="listItemClick(investigation)"
        >
          <v-list-item-avatar
            v-if="!investigation.invalid"
            :class="{'info1': investigation.finished && !investigation.result.resolved, success: investigation.finished && investigation.result.resolved}"
          >
            <v-progress-circular
              v-if="!investigation.finished"
              :width="3"
              color="success"
              indeterminate
            />
            <div v-else>
              <v-icon
                v-if=" investigation.result.resolved"
                class="white--text"
                v-text="'mdi-check'"
              />
              <v-icon
                v-else
                class="white--text"
                v-text="'mdi-gesture-tap'"
              />
            </div>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="subtitle-1 font-weight-regular ma-0 pa-0"
              style="line-height: 1.6em;"
              :class="{'text-decoration-line-through': investigation.invalid}"
            >
              {{ investigation.ordnr + '/ ' + $i18n.t(`$l.caseinv.${investigation.clientApi}`) }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="body-2 font-weight-medium ma-0 pa-0 pl-5"
              style="line-height: 1.3em;"
            >
              {{ getClientApiInfo(investigation) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle />
          </v-list-item-content>

          <v-list-item-action>
            <v-card-text class="pa-0 px-3">
              <v-btn
                rounded
                outlined
                :color="computeColorClass(investigation.result, '')"
                width="7em"
              >
                {{ $i18n.t(`$l.case.level${investigation.result.riskLevel}`) }}
              </v-btn>
            </v-card-text>
          </v-list-item-action>
        </v-list-item>
      </transition-group>
    </v-list>

    <component
      :is="taskDialogComponent"
      v-model="taskDialog"
      :data="taskDialogData"
      :current-case="currentCase"
      @close-dialog="closeDialog()"
    />
  </div>
</template>

<script>
import StolenIdentityCardDialog from '../stepper/taskDialogs/StolenIdentityCardDialog.vue'
import LegalRepresentativeDialog from '../stepper/taskDialogs/LegalRepresentativeDialog.vue'
import CountryRiskDialog from '../stepper/taskDialogs/CountryRiskDialog.vue'
import AuthorizedRepresentativesDialog from './../stepper/taskDialogs/AuthorizedRepresentativesDialog.vue'
import DeclarationOfFundsDialog from '../stepper/taskDialogs/DeclarationOfFundsDialog.vue'
import ConsentStatutoryBodyDialog from '../stepper/taskDialogs/ConsentStatutoryBodyDialog.vue'
import PersonWithActivityDialog from '../stepper/taskDialogs/PersonWithActivityDialog.vue'
import RefinitivWorldCheckDialog from '../stepper/taskDialogs/RefinitivWorldCheckDialog.vue'
import UltimateBeneficialOwnerDialog from '../stepper/taskDialogs/UltimateBeneficialOwnerDialog.vue'
import RiskAreaBusinessDialog from '../stepper/taskDialogs/RiskAreaBusinessDialog.vue'
import IdScanDialog from '../stepper/taskDialogs/IdScanDialog.vue'
import MediaCheckDialog from '../stepper/taskDialogs/MediaCheckDialog.vue'
import RepairInvestigationDialog from '../stepper/taskDialogs/RepairInvestigationDialog.vue'

// PEP
import EveryPoliticianListDialog from '../stepper/taskDialogs/pepDialogs/EveryPoliticianListDialog.vue'
import PoliticallyExponedPersonDialog from '../stepper/taskDialogs/pepDialogs/PoliticallyExponedPersonDialog.vue'
import osciaDialog from '../stepper/taskDialogs/pepDialogs/osciaDialog.vue'
import oseumDialog from '../stepper/taskDialogs/pepDialogs/oseumDialog.vue'

// SANCTION LIST
import EuropeanSanctionListDialog from '../stepper/taskDialogs/sanctionListDialogs/EuropeanSanctionListDialog.vue'
import USSanctionListDialog from '../stepper/taskDialogs/sanctionListDialogs/USSanctionListDialog.vue'
import ConsolidatedSanctionsSdnListDialog from '../stepper/taskDialogs/sanctionListDialogs/ConsolidatedSanctionsSdnListDialog.vue'

export default {
  name: 'TaskList',
  components: {
    StolenIdentityCardDialog,
    LegalRepresentativeDialog,
    CountryRiskDialog,
    AuthorizedRepresentativesDialog,
    DeclarationOfFundsDialog,
    ConsentStatutoryBodyDialog,
    PersonWithActivityDialog,
    PoliticallyExponedPersonDialog,
    EuropeanSanctionListDialog,
    RefinitivWorldCheckDialog,
    USSanctionListDialog,
    UltimateBeneficialOwnerDialog,
    RiskAreaBusinessDialog,
    EveryPoliticianListDialog,
    ConsolidatedSanctionsSdnListDialog,
    osciaDialog,
    oseumDialog,
    IdScanDialog,
    MediaCheckDialog,
    RepairInvestigationDialog
  },
  props: {
    participantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      taskDialog: false,
      taskDialogData: null,
      taskDialogComponent: '',
      polling: null,
      // This is order definition for tasks
      clientApiList: ['ID', 'LR', 'CNTR', 'SPS', 'DOF', 'CSB', 'PWA', 'PEP', 'EUSL', 'WC1', 'USDN', 'UBO', 'RAB', 'EPL', 'CSSDN', 'OSCIA', 'OSEUM', 'IDS', 'MCH']
    }
  },
  computed: {
    sortedParticipantInvestigations () {
      let allInvestigations = Array.from(this.participant.investigations)
      allInvestigations.forEach(investigation => {
        investigation.ordnr = this.clientApiList.indexOf(investigation.clientApi) + 1
      })
      allInvestigations = allInvestigations.sort((a, b) => (a.ordnr === b.ordnr) ? 0 : (a.ordnr > b.ordnr ? 1 : -1))
      allInvestigations = allInvestigations.sort((a, b) => (a.result.resolved === b.result.resolved) ? 0 : (a.result.resolved ? 1 : -1))
      return allInvestigations
    },
    currentCase () {
      return this.$store.getters.getCurrentCase
    },
    participant () {
      return this.currentCase.participants.filter(p => p.id === this.participantId)[0]
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  created () {
    if (!this.isAllTasksFinished()) {
      this.startPooling()
    }
  },
  methods: {
    isAllTasksFinished () {
      var result = true
      this.participant.investigations.forEach(investigation => {
        if (!investigation.finished) {
          result = false
        }
      })
      return result
    },
    startPooling () { // what if server gives always 400 response, prevent infinit pooling
      this.polling = setInterval(() => {
        if (!this.isAllTasksFinished()) { // && we are on step 2
          this.$xapi.get(`case/caseId/${this.currentCase.caseId}`)
            .then(r => {
              this.$store.commit('SET_CURRENT_CASE', r.data)
            })
        } else {
          clearInterval(this.polling)
        }
      }, 3000)
      setTimeout(() => {
        clearInterval(this.polling)
      }, 60000)
    },
    getClientApiInfo (investigation) {
      switch (investigation.clientApi) {
        case 'ID':
          investigation.additionalInfo = this.participant.formData.idCardNumber ? this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.idCardNumber : this.$i18n.t(`$l.caseinvadd.IDnotSett`)
          break
        case 'LR':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.birthDate
          break
        case 'CNTR':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.$utils.formatCountry(this.participant.formData.country)
          break
        case 'SPS':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'DOF':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'CSB':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'PWA':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.businessId
          break
        case 'PEP':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'EUSL':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'WC1':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'USDN':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'UBO':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'RAB':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'EPL':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'CSSDN':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'OSCIA':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'OSEUM':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`) + this.participant.formData.subject
          break
        case 'IDS':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
        case 'MCH':
          investigation.additionalInfo = this.$i18n.t(`$l.caseinvadd.${investigation.clientApi}`)
          break
      }
      return investigation.additionalInfo
    },
    closeDialog (event) {
      this.taskDialog = false
      this.taskDialogData = null
      this.taskDialogComponent = ''
    },
    listItemClick (investigation) {
      this.taskDialog = false
      this.taskDialogData = null
      this.taskDialogComponent = ''
      let title = `$l.caseinv.${investigation.clientApi}`
      this.taskDialogData = { title: title, investigation: investigation, participant: this.participant }
      switch (investigation.clientApi) {
        case 'ID':
          this.taskDialogComponent = 'StolenIdentityCardDialog'
          break
        case 'LR':
          this.taskDialogComponent = 'LegalRepresentativeDialog'
          break
        case 'CNTR':
          this.taskDialogComponent = 'CountryRiskDialog'
          break
        case 'SPS':
          this.taskDialogComponent = 'AuthorizedRepresentativesDialog'
          break
        case 'DOF':
          this.taskDialogComponent = 'DeclarationOfFundsDialog'
          break
        case 'CSB':
          this.taskDialogComponent = 'ConsentStatutoryBodyDialog'
          break
        case 'PWA':
          this.taskDialogComponent = 'PersonWithActivityDialog'
          break
        case 'PEP':
          this.taskDialogComponent = 'PoliticallyExponedPersonDialog'
          break
        case 'EUSL':
          this.taskDialogComponent = 'EuropeanSanctionListDialog'
          break
        case 'WC1':
          this.taskDialogComponent = 'RefinitivWorldCheckDialog'
          break
        case 'USDN':
          this.taskDialogComponent = 'USSanctionListDialog'
          break
        case 'UBO':
          this.taskDialogComponent = 'UltimateBeneficialOwnerDialog'
          break
        case 'RAB':
          this.taskDialogComponent = 'RiskAreaBusinessDialog'
          break
        case 'EPL':
          this.taskDialogComponent = 'EveryPoliticianListDialog'
          break
        case 'CSSDN':
          this.taskDialogComponent = 'ConsolidatedSanctionsSdnListDialog'
          break
        case 'OSCIA':
          this.taskDialogComponent = 'osciaDialog'
          break
        case 'OSEUM':
          this.taskDialogComponent = 'oseumDialog'
          break
        case 'IDS':
          this.taskDialogComponent = 'IdScanDialog'
          break
        case 'MCH':
          this.taskDialogComponent = 'MediaCheckDialog'
          break
      }
      // if (!investigation.finished) {
      //   this.taskDialogComponent = 'RepairInvestigationDialog'
      // }
      this.taskDialog = true
    },
    computeColorClass (result, append) {
      let color = 'label'
      if (result.riskLevel === 0) {
        color = 'success'
      }
      if (result.riskLevel === 1) {
        color = 'warning'
      }
      if (result.riskLevel === 2) {
        color = 'error'
      }
      return color + append
    }
  }
}
</script>

<style lang="scss" scoped>
  .task_list.v-list-item .v-list-item__action {
      margin: 0px;
      padding: 0px;
  }
  .task_list.v-list-item .v-list-item__content {
      margin: 0px;
      padding: 0px;
  }
  .task_list.v-list-item .v-list-item__avatar {
      margin: 0em 1em 0em 0em;
      padding: 0px;
  }
  .task_list ul {
    padding: 0px;
  }
</style>
